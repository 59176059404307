/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import '../css/debtorsTable.css';
import firebase from '../firebaseOLD.js'; // Import Firebase
import EditOrderForm from './EditOrderForm.js';
import ConfirmationModal from './ConfirmationModal'; // Import the modal component
import CompanyInfoModal from './CompanyInfoModal'; // Import the CompanyInfoModal component
import { ref, get } from 'firebase/database';
import { db2 } from '../firebase.js';

const DebtorsTable = ({ user, userRole, orders, vendor }) => {
  const [previewUrl, setPreviewUrl] = useState('');
  const [editingOrder, setEditingOrder] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [showCompanyInfoModal, setShowCompanyInfoModal] = useState(false);

  const handleEditOrder = (order) => {
    setEditingOrder(order);
  };

  const handleCloseModal = () => {
    setEditingOrder(null);
  };

  const handleArchiveOrder = (order) => {
    setSelectedOrder(order);
    setShowConfirmationModal(true);
  };

  const handleOpenCompanyInfo = async (buyerName) => {
    try {
      const companiesRef = ref(db2, 'companies');
      const snapshot = await get(companiesRef);
      if (snapshot.exists()) {
        const companies = snapshot.val();
        const companyList = Object.values(companies);
        const foundCompany = companyList.find(company => company.ime === buyerName);
        if (foundCompany) {
          setCompanyInfo(foundCompany);
          setShowCompanyInfoModal(true);
        } else {
          console.log('Company not found');
        }
      } else {
        console.log('No data available');
      }
    } catch (error) {
      console.error('Error fetching company info:', error);
    }
  };

  const handleConfirmArchive = async () => {
    if (selectedOrder) {
      try {
        // Create a reference to the specific order's path
        const orderRef = firebase.database().ref(`orders/${selectedOrder.OrderID}`);
        
        // Update the `Status` attribute to "Archived"
        await orderRef.update({
          Status: 'Archived'
        });
        
        console.log('Order status updated to Archived!');
      } catch (error) {
        console.error('Error updating order status:', error);
      } finally {
        // Close the modal and reset the selected order
        setShowConfirmationModal(false);
        setSelectedOrder(null);
      }
    }
  };

  const handleCancelConfirmation = () => {
    setShowConfirmationModal(false);
    setSelectedOrder(null);
  };

  // Function to close preview window
  const handleClosePreview = () => {
    setPreviewUrl('');
  };

  // Function to parse date from DD-MM-YYYY string
  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day); // Months are 0-based in JavaScript
  };

  // Function to get the background class based on due date
  const getDueDateClass = (dueDate) => {
    const today = new Date();
    const due = parseDate(dueDate);
    const diffTime = due - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 7) return 'purple';
    if (diffDays < 15) return 'pale-orange';
    if (diffDays < 30) return 'pale-yellow';
    if (diffDays < 60) return 'pale-green';
    if (diffDays < 90) return 'pale-blue';
    if (diffDays < 120) return 'gray';
    return '';
  };

  // Function to parse a string formatted number (XXX,XXX.XX) to a float
  const parseFormattedNumber = (numberStr) => {
    return parseFloat(numberStr.replace(/,/g, ''));
  };

  // Function to format a number to the string format XXX,XXX.XX
  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  };

  // Calculate the total sum
  const totalSum = Object.values(orders).reduce((acc, order) => acc + parseFormattedNumber(order.totalSum) -parseFormattedNumber(order.Advance) || 0, 0);

  // Determine the currency suffix based on vendor
  const getCurrencySuffix = () => {
    switch (vendor) {
      case 'MK-Denari':
        return 'МКД';
      case 'MK-EUR':
      case 'EU':
        return '€';
      default:
        return '';
    }
  };

  // Print function
  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=600,width=800');
    if (printWindow) {
      const printDate = new Date().toLocaleString('en-GB', { timeZone: 'UTC' });
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Orders</title>
            <style>
              /* Add any specific styles for printing here */
              table {
                width: 100%;
                border-collapse: collapse;
              }
              th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
            </style>
          </head>
          <body>
            <h1>${vendor}</h1>
            <p>Извод издаден ${printDate}}</p>
            <table>
              <thead>
                <tr>
                  <th>Број на нарачка</th>
                  <th>Фактура</th>
                  <th>Купец</th>
                  <th>Сума</th>
                  <th>Датум Наплата</th>
                </tr>
              </thead>
              <tbody>
                ${Object.keys(orders).map((firebaseId) => {
                  const order = orders[firebaseId];
                  const rowClass = getDueDateClass(order.dueDate);
                  return `
                    <tr class="${rowClass}">
                      <td>${order.OrderID}</td>
                      <td>${order.invoiceNumber}</td>
                      <td>${order.Buyer}</td>
                      <td>${formatNumber(parseFormattedNumber(order.totalSum) - parseFormattedNumber(order.Advance)  || 0)}</td>
                      <td>${order.dueDate}</td>
                    </tr>
                  `;
                }).join('')}
                <tr>
                  <td colspan="3"><strong>Вкупно:</strong></td>
                  <td><strong>${formatNumber(totalSum)} ${getCurrencySuffix()}</strong></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    } else {
      console.error('Failed to open print window.');
    }
  };

  return (
    <div className="orders-table-container">
      <div className="header-container">
        <h2>{vendor}</h2>
        <button className="print-button" onClick={handlePrint}>Печати</button>
      </div>
      {previewUrl && (
        <div className="invoice-preview">
          <div className="preview-content">
            <button className="close-button" onClick={handleClosePreview}>&times;</button>
            <iframe src={previewUrl} title="Invoice Preview" className="preview-frame"></iframe>
          </div>
        </div>
      )}
      <table className="orders-table orders-table-3">
        <thead>
          <tr>
            <th>Број на нарачка</th>
            <th>Фактура</th>
            <th>Купец</th>
            <th>Долг<br />(Сума - Аванс)</th>
            <th>Датум Наплата</th>
            {userRole === "admin" && <th>Подеси</th>}
          </tr>
        </thead>
        {Object.keys(orders).length > 0 ? (
          <tbody>
            {Object.keys(orders).map((firebaseId) => {
              const order = orders[firebaseId];
              const rowClass = getDueDateClass(order.dueDate);
              return (
                <tr key={firebaseId} className={rowClass}>
                  {userRole === "admin" ? (
                    <td>
                      <a className='order-link' href="#" onClick={() => handleEditOrder(order)}>
                        {order.OrderID}
                      </a>
                    </td>
                  ) : (
                    <td>{order.OrderID}</td>
                  )}
                  <td>{order.invoiceNumber}</td>
                  <td>
                    <a className='order-link' href="#" onClick={() => handleOpenCompanyInfo(order.Buyer)}>
                      {order.Buyer}
                    </a>
                  </td> 
                  <td className='total-sum-number-invoice'>{formatNumber(parseFormattedNumber(order.totalSum) - parseFormattedNumber(order.Advance) || 0)}</td>
                  <td>{order.dueDate}</td>
                  {userRole === "admin" && (
                    <td>
                      <button onClick={() => handleArchiveOrder(order)}>Платено</button>
                    </td>
                  )}
                </tr>
              );
            })}
            <tr>
              <td colSpan="3"><strong>Вкупно:</strong></td>
              <td><strong>{formatNumber(totalSum)} {getCurrencySuffix()}</strong></td>
              <td></td>
              {userRole === "admin" && <td></td>}
            </tr>
          </tbody>
        ) : (
          <p>Нема Активни</p>
        )}
      </table>
      {editingOrder && (
        <EditOrderForm
          order={editingOrder}
          user={user}
          onClose={handleCloseModal}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          message="Дали сте сигурни дека сакате да го промените статусот на Архивиран?"
          onConfirm={handleConfirmArchive}
          onCancel={handleCancelConfirmation}
        />
      )}
      {showCompanyInfoModal && companyInfo && (
        <CompanyInfoModal
          company={companyInfo}
          onClose={() => setShowCompanyInfoModal(false)}
        />
      )}
    </div>
  );
};

export default DebtorsTable;
