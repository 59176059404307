/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import "../css/CompanyList.css";

const EditCompanyModal = ({ company, industries, cities, countries, onEdit, onClose }) => {
  const [editedCompany, setEditedCompany] = useState(company);

  useEffect(() => {
    setEditedCompany(company);
  }, [company]);

  // Handle changes in text inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCompany(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Handle changes in select inputs
  const handleSelectChange = (selectedOption, { name }) => {
    setEditedCompany(prevState => ({
      ...prevState,
      [name]: selectedOption ? selectedOption.value : ''
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    onEdit(editedCompany);
    onClose();
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Промени податоци за фирма:</h2>
        <form onSubmit={handleSubmit} className="form-container">
          <div className="form-group">
            <label className="label" htmlFor="ime">Име:</label>
            <input
              className="input"
              type="text"
              id="ime"
              name="ime"
              value={editedCompany.ime}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="kontakt">Контакт:</label>
            <textarea
              className="input"
              type="text"
              id="kontakt"
              name="kontakt"
              value={editedCompany.kontakt}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="adresa">Адреса:</label>
            <textarea
              className="input"
              type="text"
              id="adresa"
              name="adresa"
              value={editedCompany.adresa}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="web">Web страна:</label>
            <input
              className="input"
              type="text"
              id="web"
              name="web"
              value={editedCompany.web}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="interes">Интерес:</label>
            <input
              className="input"
              type="text"
              id="interes"
              name="interes"
              value={editedCompany.interes}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="sorabotka">Соработка:</label>
            <textarea
              className="input"
              type="text"
              id="sorabotka"
              name="sorabotka"
              value={editedCompany.sorabotka}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="oprema">Опрема:</label>
            <textarea
              className="input"
              type="text"
              id="oprema"
              name="oprema"
              value={editedCompany.oprema}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="google_location">Локација:</label>
            <input
              className="input"
              type="text"
              id="google_location"
              name="google_location"
              value={editedCompany.google_location}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="drzava_ime">Држава:</label>
            <Select
              className="input-dropdown"
              id="drzava_ime"
              name="drzava_ime"
              options={countries}
              value={countries.find(option => option.value === editedCompany.drzava_ime)}
              onChange={handleSelectChange}
              placeholder={editedCompany.drzava_ime}
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="grad_ime">Град:</label>
            <Select
              className="input-dropdown"
              id="grad_ime"
              name="grad_ime"
              options={cities}
              value={cities.find(option => option.value === editedCompany.grad_ime)}
              onChange={handleSelectChange}
              placeholder={editedCompany.grad_ime}
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="industrija_naziv">Индустрија:</label>
            <Select
              className="input-dropdown"
              id="industrija_naziv"
              name="industrija_naziv"
              options={industries}
              value={industries.find(option => option.value === editedCompany.industrija_naziv)}
              onChange={handleSelectChange}
              placeholder={editedCompany.industrija_naziv}
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="region_ime">Регион:</label>
            <input
              className="input"
              type="text"
              id="region_ime"
              name="region_ime"
              value={editedCompany.region_ime}
              onChange={handleInputChange}
            />
          </div>
          <button type="submit" className="submit-button">Зачувај промени</button>
        </form>
      </div>
    </div>
  );
};

export default EditCompanyModal;
