/* eslint-disable react/prop-types */
/* EventModal.js */
import React, { useState, useEffect } from 'react';
import { db2, ref, update, remove, get } from '../firebase'; // Import the necessary functions from your firebaseConfig
import '../css/EventModal.css'; // Import styles
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CompanyDetail from './CompanyDetail';

const EventModal = ({ data, onClose }) => {
  const [formData, setFormData] = useState(data);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        if (data.companyName) {
          const companiesRef = ref(db2, 'companies');
          const snapshot = await get(companiesRef);
          if (snapshot.exists()) {
            const companyList = snapshot.val();
            const companyData = Object.values(companyList).find(
              (comp) => comp.ime === data.companyName
            );
            setCompany(companyData || {});
          }
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    fetchCompany();
  }, [data.companyName]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      const eventRef = ref(db2, `events/${data.id}`);
      await update(eventRef, formData);
      onClose();
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? formatDate(date) : '';
    setFormData({ ...formData, date: formattedDate });
  };

  const handleDelete = async () => {
    try {
      const eventRef = ref(db2, `events/${data.id}`);
      await remove(eventRef);
      onClose();
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  const handleToggleStatus = async () => {
    try {
      const eventRef = ref(db2, `events/${data.id}`);
      await update(eventRef, { ...formData, isActive: !formData.isActive });
      onClose();
    } catch (error) {
      console.error('Error toggling event status:', error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content-container">
        <button className="modal-close-button" onClick={onClose}>&times;</button>
        <h2 className="modal-title">Информации за активност</h2>
        <div className="modal-form-container">
          <div className="form-group">
            <label className="label">Назив на Фирма:</label>
            <input
              className="input"
              type="text"
              name="companyName"
              value={formData.companyName || ''}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label className="label">Опис:</label>
            <textarea
              className="modal-textarea"
              name="description"
              value={formData.description || ''}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="form-group">
            <label className="label">Предвиден датум:</label>
            <DatePicker
              selected={formData.date ? new Date(formData.date) : null} // Convert string to Date object
              onChange={handleDateChange}
              dateFormat="dd-MM-yyyy"
              className="input"
              placeholderText="датум до"
              popperPlacement='bottom-start'
            />
          </div>
          <div className="button-group">
            <button className="modal-save-button" onClick={handleSave}>Зачувај</button>
            <button className="modal-delete-button" onClick={handleDelete}>Избриши</button>
            <button
              className={`modal-toggle-status-button ${formData.isActive ? 'active' : 'inactive'}`}
              onClick={handleToggleStatus}
            >
              {formData.isActive ? 'Промени во Неактивен' : 'Промени во Активен'}
            </button>
          </div>
          {company && (
            <div className="company-info">
              <h2>Информации за фирмата</h2>
              <h3>{company.ime}</h3>
              <CompanyDetail title="Контакт" content={company.kontakt} />
            <CompanyDetail title="Адреса" content={company.adresa} />
            <CompanyDetail title="Web страна" content={<a href={company.web} target="_blank" rel="noopener noreferrer">{company.web}</a>} />
            <CompanyDetail title="Интерес" content={company.interes} />
            <CompanyDetail title="Соработка" content={company.sorabotka} />
            <CompanyDetail title="Опрема" content={company.oprema} />
            <CompanyDetail title="Локација" content={company.google_location} />
            <CompanyDetail title="Држава" content={company.drzava_ime} />
            <CompanyDetail title="Град" content={company.grad_ime} />
            <CompanyDetail title="Индустрија" content={company.industrija_naziv} />
            <CompanyDetail title="Регион" content={company.region_ime} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventModal;
