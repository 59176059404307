/* eslint-disable react/prop-types */
// src/components/AddAttributeModal.js
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { ref, push, set, get } from 'firebase/database';
import { db2 } from '../firebase'; // Import the secondary database reference
import "../css/CompanyList.css";
import emojiFlags from 'emoji-flags'; // Import emoji-flags library

const AddAttributeModal = ({ type, onClose }) => {
  const [name, setName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);

  // Generate country options with emojis
  const countryEmojiOptions = Object.values(emojiFlags).map(country => ({
    value: country.code,
    label: `${country.name} ${country.emoji}`
  }));

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesRef = ref(db2, 'countries');
        const snapshot = await get(countriesRef);
        const data = snapshot.val();

        const countryOptions = Object.keys(data).map(key => ({
          value: key,
          label: data[key].naziv,
        }));

        setCountries(countryOptions);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (type === 'city' && !selectedCountry) {
        alert('Please select a country.');
        return;
      }

      if (type === 'country' && (!name || !selectedCountry)) {
        alert('Please enter both name and select a country emoji.');
        return;
      }

      // Determine the path based on the type
      const path = type === 'industry' ? 'industrija' : type === 'city' ? 'cities' : 'countries';

      // Create a reference to the respective path
      const attributesRef = ref(db2, path);

      // Use push to create a new attribute under the list
      const newAttributeRef = push(attributesRef);

      const value = type === 'country' ? { naziv: `${name} ${selectedCountry.label.split(' ').pop()}` } : { naziv: name };
      if (type === 'city' && selectedCountry) {
        value.drzava = selectedCountry.label;
      }

      // Set the value of the new attribute
      await set(newAttributeRef, value);

      console.log(`${type} added successfully`);
      onClose();
    } catch (error) {
      console.error(`Error adding ${type}:`, error);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Додај {type === 'industry' ? 'Индустрија' : type === 'city' ? 'Град' : 'Држава'}</h2>
        <form onSubmit={handleSubmit} className="form-container">
          {type === 'city' && (
            <>
              <div className="form-group">
                <label className="label" htmlFor="country">Избери држава:</label>
                <Select
                  id="country"
                  name="country"
                  options={countries}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  placeholder="Избери држава"
                  required
                />
              </div>
              {selectedCountry && (
                <div className="form-group">
                  <label className="label" htmlFor="name">Име на град:</label>
                  <input
                    className="input"
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleNameChange}
                    required
                  />
                </div>
              )}
            </>
          )}
          {type === 'country' && (
            <div className="form-group">
              <label className="label" htmlFor="country-name">Назив на држава:</label>
              <div className="input-group">
                <input
                  className="input"
                  type="text"
                  id="country-name"
                  name="name"
                  value={name}
                  onChange={handleNameChange}
                  placeholder="Име на држава"
                  required
                />
                <Select
                  id="emoji"
                  name="emoji"
                  options={countryEmojiOptions}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  placeholder="Знаме"
                  required
                  className="emoji-select"
                />
              </div>
            </div>
          )}
          {type !== 'city' && type !== 'country' && (
            <div className="form-group">
              <label className="label" htmlFor="name">
                {type === 'industry' ? 'Назив на индустрија' : 'Име на држава'}:
              </label>
              <input
                className="input"
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={handleNameChange}
                required
              />
            </div>
          )}
          <div className='extra-space'>
          <button type="submit" className="submit-button">Додај</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAttributeModal;
