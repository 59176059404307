import React, { useState, useEffect } from 'react';
import { ref, get, set } from 'firebase/database';
import { db2 } from '../firebase';
import "../css/CompanyList.css";
import AddCompanyModal from './AddCompanyModal';
import EditCompanyModal from './EditCompanyModal';
import AddAttributeModal from './AddAttributeModal';
import CompanyDetail from './CompanyDetail';
import Select from 'react-select';

const CompanyList = () => {
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);
  const [showAddIndustryModal, setShowAddIndustryModal] = useState(false);
  const [showAddCityModal, setShowAddCityModal] = useState(false);
  const [showAddCountryModal, setShowAddCountryModal] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const industriesRef = ref(db2, 'industrija');
        const industriesSnapshot = await get(industriesRef);
        if (industriesSnapshot.exists()) {
          setIndustries(Object.values(industriesSnapshot.val()).map(industry => ({
            value: industry.naziv,
            label: industry.naziv,
          })));
        }

        const citiesRef = ref(db2, 'cities');
        const citiesSnapshot = await get(citiesRef);
        if (citiesSnapshot.exists()) {
          setCities(Object.values(citiesSnapshot.val()).map(city => ({
            value: city.naziv,
            label: city.naziv,
            country: city.drzava, // Ensure this field exists
          })));
        }

        const countriesRef = ref(db2, 'countries');
        const countriesSnapshot = await get(countriesRef);
        if (countriesSnapshot.exists()) {
          setCountries(Object.values(countriesSnapshot.val()).map(country => ({
            value: country.naziv,
            label: country.naziv,
          })));
        }
      } catch (error) {
        console.error('Error fetching filter data:', error);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        let queryRef = ref(db2, 'companies');
        const snapshot = await get(queryRef);
        if (snapshot.exists()) {
          let companyList = Object.values(snapshot.val());

          if (selectedCity) {
            companyList = companyList.filter(company => company.grad_ime === selectedCity.value);
          }
          if (selectedCountry) {
            companyList = companyList.filter(company => company.drzava_ime === selectedCountry.value);
          }
          if (selectedIndustry) {
            companyList = companyList.filter(company => company.industrija_naziv === selectedIndustry.value);
          }
          if (searchQuery) {
            companyList = companyList.filter(company => company.ime.toLowerCase().includes(searchQuery.toLowerCase()));
          }

          setCompanies(companyList);
          setFilteredCompanies(companyList.slice(0, 50)); // Limit to 50 results

          // Automatically select the first company if there are results
          if (companyList.length > 0) {
            setSelectedCompany(companyList[0]);
          } else {
            setSelectedCompany(null); // Clear selection if no results
          }
        } else {
          console.log('No data available');
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, [selectedCity, selectedCountry, selectedIndustry, searchQuery]);

  useEffect(() => {
    if (selectedCountry) {
      const countryCities = cities.filter(city => city.country === selectedCountry.value);
      setFilteredCities(countryCities);
    } else {
      setFilteredCities([]);
      setSelectedCity(null); // Reset selected city if no country is selected
    }
  }, [selectedCountry, cities]);

  const handleCompanyClick = (company) => {
    setSelectedCompany(company);
  };

  const handleAddCompany = (newCompany) => {
    const newCompaniesList = [...companies, newCompany];
    setCompanies(newCompaniesList);
    setFilteredCompanies(newCompaniesList.slice(0, 50));
    const newCompanyRef = ref(db2, `companies/${newCompany.id}`);
    set(newCompanyRef, newCompany)
      .then(() => console.log('Company added successfully'))
      .catch(error => console.error('Error adding company:', error));
  };

  const handleEditCompany = (updatedCompany) => {
    const updatedCompaniesList = companies.map(company =>
      company.id === updatedCompany.id ? updatedCompany : company
    );
    setCompanies(updatedCompaniesList);
    setFilteredCompanies(updatedCompaniesList.slice(0, 50));
    const companyRef = ref(db2, `companies/${updatedCompany.id}`);
    set(companyRef, updatedCompany)
      .then(() => console.log('Company updated successfully'))
      .catch(error => console.error('Error updating company:', error));
  };

  return (
    <div className="page-container">
      <div className="sidebar">
        <div className="filters">
          <div className="filter-dropdown">
            <input
              className="input"
              type="text"
              placeholder="Пребарај фирми по име..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="filter-dropdown">
            <label htmlFor="countryFilter">Држава:</label>
            <Select
              id="countryFilter"
              options={[{ value: null, label: 'Изберете држава' }, ...countries]}
              value={selectedCountry}
              isClearable
              onChange={selected => {
                setSelectedCountry(selected);
                if (!selected) {
                  setSelectedCity(null); // Clear city filter if no country is selected
                }
              }}
              placeholder="Изберете држава"
              className="filter-dropdown"
            />
          </div>
          {selectedCountry && (
            <div className="filter-dropdown">
              <label htmlFor="cityFilter">Град:</label>
              <Select
                id="cityFilter"
                options={[{ value: null, label: 'Изберете град' }, ...filteredCities]}
                value={selectedCity}
                onChange={setSelectedCity}
                placeholder="Изберете град"
                className="filter-dropdown"
                isClearable
              />
            </div>
          )}
          <div className="filter-dropdown">
            <label htmlFor="industryFilter">Индустрија:</label>
            <Select
              id="industryFilter"
              options={[{ value: null, label: 'Изберете индустрија' }, ...industries]}
              value={selectedIndustry}
              onChange={setSelectedIndustry}
              placeholder="Изберете индустрија"
              className="filter-dropdown"
              isClearable
            />
          </div>
        </div>
        <div className="add-buttons">
          <button onClick={() => setShowAddCompanyModal(true)}>Додај фирма</button>
          <button onClick={() => setShowAddIndustryModal(true)}>Додај Индустрија</button>
          <button onClick={() => setShowAddCityModal(true)}>Додај Град</button>
          <button onClick={() => setShowAddCountryModal(true)}>Додај Држава</button>
        </div>
      </div>
      <div className="company-list">
        {filteredCompanies.map(company => (
          <div
            key={company.id}
            className="company-card"
            onClick={() => handleCompanyClick(company)}
          >
            <h4 className="card-h4">{company.ime}</h4>
            <p className="card-p">{company.grad_ime}, {company.drzava_ime}</p>
            <p className="card-p">{company.industrija_naziv}</p>
          </div>
        ))}
      </div>
      <div className="company-details">
        {selectedCompany && (
          <>
            <h3>{selectedCompany.ime}</h3>
            <CompanyDetail title="Контакт" content={selectedCompany.kontakt} />
            <CompanyDetail title="Адреса" content={selectedCompany.adresa} />
            <CompanyDetail title="Web страна" content={<a href={selectedCompany.web} target="_blank" rel="noopener noreferrer">{selectedCompany.web}</a>} />
            <CompanyDetail title="Интерес" content={selectedCompany.interes} />
            <CompanyDetail title="Соработка" content={selectedCompany.sorabotka} />
            <CompanyDetail title="Опрема" content={selectedCompany.oprema} />
            <CompanyDetail title="Локација" content={selectedCompany.google_location} />
            <CompanyDetail title="Држава" content={selectedCompany.drzava_ime} />
            <CompanyDetail title="Град" content={selectedCompany.grad_ime} />
            <CompanyDetail title="Индустрија" content={selectedCompany.industrija_naziv} />
            <CompanyDetail title="Регион" content={selectedCompany.region_ime} />
            <button className="edit-company-button" onClick={() => setShowEditCompanyModal(true)}>
              Промени
            </button>
          </>
        )}
      </div>

      {showAddCompanyModal && <AddCompanyModal onClose={() => setShowAddCompanyModal(false)} onAdd={handleAddCompany} cities={cities} industries={industries} countries={countries}/>}
      {showEditCompanyModal && <EditCompanyModal onClose={() => setShowEditCompanyModal(false)} onEdit={handleEditCompany} company={selectedCompany} cities={cities} industries={industries} countries={countries} />}
      {showAddIndustryModal && <AddAttributeModal type="industry" onClose={() => setShowAddIndustryModal(false)} />}
      {showAddCityModal && <AddAttributeModal type="city" onClose={() => setShowAddCityModal(false)} />}
      {showAddCountryModal && <AddAttributeModal type="country" onClose={() => setShowAddCountryModal(false)} />}
    </div>
  );
};

export default CompanyList;
