/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars } from '@fortawesome/free-solid-svg-icons';
import {ref, db, get} from "../firebase";
import '../css/navBar.css'; // Import the NavBar.css for styling

const NavBar = ({ user, onLogout }) => {
  const [userRole, setUserRole] = useState('');
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (user) {
        try {
          const userRef = ref(db, `users/${user.uid}/role`);
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            setUserRole(snapshot.val());
          } else {
            setUserRole('undefined');
          }
        } catch (error) {
          console.error('Error fetching user role:', error);
          setUserRole('undefined');
        }
      }
    };

    fetchUserRole();
  }, [user]);

  const handleLogout = () => {
    onLogout();
    setIsNavOpen(false); // Close nav on logout
  };

  const toggleNav = () => {
    setIsNavOpen(prevState => !prevState);
  };

  const handleLinkClick = () => {
    setIsNavOpen(false); // Close nav when a link is clicked
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsNavOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav ref={navRef} className={`navbar ${isNavOpen ? 'open' : ''}`}>
      <button className="hamburger" onClick={toggleNav}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      <div className="nav-content">
        <ul className={`pages ${isNavOpen ? 'show' : ''}`}>
          <li><NavLink to="/" exact activeClassName="active" onClick={handleLinkClick}>Тековни</NavLink></li>
          <li><NavLink to="/debtors" exact activeClassName="active" onClick={handleLinkClick}>Должници</NavLink></li>
          <li><NavLink to="/archive" exact activeClassName="active" onClick={handleLinkClick}>Архива</NavLink></li>
          <li><NavLink to="/companies" exact activeClassName="active" onClick={handleLinkClick}>Фирми</NavLink></li>
          <li><NavLink to="/activities" exact activeClassName="active" onClick={handleLinkClick}>Активности</NavLink></li>
          {userRole === "admin" && <li><NavLink to="/adminPanel" exact activeClassName="active" onClick={handleLinkClick}>Администрација</NavLink></li>}
          <li><NavLink to="/dashboard" exact activeClassName="active" onClick={handleLinkClick}>Табло</NavLink></li>
        </ul>
        {user && (
          <ul className={`actions ${isNavOpen ? 'show' : ''}`}>
            <li className="user-name"><FontAwesomeIcon icon={faUser} /> {user.displayName}{userRole === 'admin' && ` (Admin)`}</li>
            <li><NavLink to="/profile" exact activeClassName="active" onClick={handleLinkClick}>Профил</NavLink></li>
            <li><button className="logout-btn" onClick={handleLogout}>Logout</button></li>
          </ul>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
