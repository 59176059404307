import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
import styles from '../css/resetPasswordPage.module.css'; // Import the CSS module

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // New state for confirm password
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    const oobCode = searchParams.get('oobCode');
    
    if (oobCode) {
      if (newPassword !== confirmPassword) {
        setError('Passwords do not match.');
        return;
      }
      
      try {
        const auth = getAuth();
        await confirmPasswordReset(auth, oobCode, newPassword);
        setMessage('Password has been reset successfully!');
        setError(''); // Clear any previous error messages
      } catch (err) {
        console.error('Password reset error:', err); // For debugging purposes
        setError('Error resetting password: ' + (err.message || 'Unknown error'));
        setMessage(''); // Clear any previous success messages
      }
    } else {
      setError('Invalid or expired password reset link.');
      setMessage(''); // Clear any previous success messages
    }
  };

  return (
    <div className={styles.resetPasswordContainer}>
      <div className={styles.resetPasswordCard}>
        <h2 className={styles.resetPasswordTitle}>Reset Password</h2>
        <form onSubmit={handlePasswordReset}>
          <div className={styles.inputGroup}>
            <input
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className={styles.resetPasswordInput}
            />
            <input
              type="password"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className={styles.confirmPasswordInput} // Apply the confirm password input style
            />
          </div>
          <button type="submit" className={styles.resetPasswordButton}>
            Reset Password
          </button>
        </form>
        {message && <p className={styles.successMessage}>{message}</p>}
        {error && <p className={styles.errorMessage}>{error}</p>}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
