// ForgotPasswordPage.js
import { NavLink } from 'react-router-dom';
import React, { useState } from 'react';
import { auth } from '../firebaseOLD';
import styles from '../css/ForgotPasswordPage.module.css';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleResetPassword = async () => {
    try {
      await auth.sendPasswordResetEmail(email);
      setMessage('Password reset email sent. Please check your inbox.');
      setError('');
    } catch (error) {
      setError('Error sending password reset email. Please try again.' + {error});
      setMessage('');
    }
  };

  return (
    <div className={styles.forgotPasswordContainer}>
      <div className={styles.forgotPasswordBox}>
        <h2>Forgot Password</h2>
        {message && <p style={{ color: 'green' }}>{message}</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <input className={styles.ForgotPasswordInput}
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={handleResetPassword}>Send Reset Email</button>
        <br ></br>
        <br ></br>
        <br ></br>
        <div>
        <NavLink to="/signin" exact activeClassName={styles.forgotPasswordButton}>
        Назад кон најава
              </NavLink>
      </div>
    </div>
    </div>
  );
};

export default ForgotPasswordPage;

