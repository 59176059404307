/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink for routing
import { db2, ref, query, onValue, off, orderByChild, startAt, endAt } from '../firebase'; // Firebase setup
import EventCard from '../elements/EventCard'; // Import EventCard component
import '../css/EventWidget.css'; // Import the CSS for the macOS look

const EventWidget = ({ userName }) => {
  const [events, setEvents] = useState([]);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
  const [hasOverdue, setHasOverdue] = useState(false);
  const [todaysEvents, setTodaysEvents] = useState([]);
  const [tomorrowsEvents, setTomorrowsEvents] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);
    const twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 2);

    //const currentDateISO = currentDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD
    const tomorrowISO = tomorrow.toISOString().split('T')[0];
    const twoDaysLaterISO = twoDaysLater.toISOString().split('T')[0];

    const eventsRef = ref(db2, 'events');

    // Query for events that are in the range from before today up to two days later
    const eventsQuery = query(
      eventsRef,
      orderByChild('date'),
      startAt('0000-00-00'), // Fetch events from any date
      endAt(twoDaysLaterISO)
    );

    const fetchEventsAndListen = () => {
      const listener = onValue(eventsQuery, (snapshot) => {
        if (snapshot.exists()) {
          const allEvents = Object.entries(snapshot.val()).map(([key, value]) => ({
            id: key,
            ...value,
          }));

          // Filter events based on the current user
          const userEvents = allEvents.filter(event => event.createdBy === userName);

          // Sort events by date
          userEvents.sort((a, b) => new Date(a.date) - new Date(b.date));

          // Identify today's date, tomorrow's date, and past due dates
          const today = new Date().toISOString().split('T')[0];
          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          const yesterdayISO = yesterday.toISOString().split('T')[0];

          // Separate today's, tomorrow's, and past due events
          const todaysEventsList = userEvents.filter(event => event.date === today);
          const tomorrowsEventsList = userEvents.filter(event => event.date === tomorrowISO);
          const pastDueEvents = userEvents.filter(event => event.date < today && event.date >= yesterdayISO);

          setEvents(userEvents);
          setTodaysEvents(todaysEventsList);
          setTomorrowsEvents(tomorrowsEventsList);
          setHasOverdue(pastDueEvents.length > 0);
        } else {
          setEvents([]);
          setTodaysEvents([]);
          setTomorrowsEvents([]);
          setHasOverdue(false);
        }
      });

      return () => off(eventsRef, 'value', listener);
    };

    fetchEventsAndListen();
  }, [userName]);

  return (
    <div className="macos-window">
      <div className="macos-header">
        <h2 className="window-title">Активности за <br /> денес и утре</h2>
        <NavLink to="/activities" exact activeClassName="active" className="all-events-link">
          Сите Активности
        </NavLink>
      </div>
      <div className="macos-content">
        {hasOverdue && (
          <div className="overdue-notice">Изминат датум</div>
        )}
        {/* Render past due events first */}
        {events
          .filter(event => event.date < new Date().toISOString().split('T')[0])
          .map((event) => {
            const backgroundColor = '#f8d7da'; // Red for past due events
            return isLargeScreen ? (
              <EventCard
                key={event.id}
                event={event}
                onDetailsClick={() => console.log('Details clicked for', event.id)}
                backgroundColor={backgroundColor}
              />
            ) : (
              <div
                key={event.id}
                className="event-item past-due"
              >
                <h3>{event.title}</h3>
                <p>{event.companyName}</p>
                <p>{new Date(event.date).toLocaleDateString('en-GB')}</p>
              </div>
            );
          })}
        {/* Render today's events */}
        {todaysEvents.length > 0 && (
          <>
            <div className="event-notice rounded-card">за денес</div>
            {todaysEvents.map((event) => (
              <EventCard
                key={event.id}
                event={event}
                onDetailsClick={() => console.log('Details clicked for', event.id)}
                backgroundColor="#ffffe0" // Light yellow for today's events
              />
            ))}
          </>
        )}
        {/* Render tomorrow's events */}
        {tomorrowsEvents.length > 0 && (
          <>
            <div className="event-notice rounded-card">за утре</div>
            {tomorrowsEvents.map((event) => (
              <EventCard
                key={event.id}
                event={event}
                onDetailsClick={() => console.log('Details clicked for', event.id)}
                backgroundColor="#ffffff" // Default white for tomorrow's events
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default EventWidget;
