/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import firebase from '../firebaseOLD';
import { ref, get } from 'firebase/database';
import { db2 } from '../firebase';
import '../css/addOrderFrom.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Utility function to format Date objects as DD-MM-YYYY
const formatDate = (date) => {
  if (!(date instanceof Date) || isNaN(date.getTime())) return ''; // Check if date is valid
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// Utility function to parse DD-MM-YYYY strings to Date objects


const AddOrderForm = ({ userRole, user, vendorDestination, currentInvoice }) => {
  const [showForm, setShowForm] = useState(false);
  const [uploading, setUploading] = useState({ invoice: false, other: false });
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [buyerInput, setBuyerInput] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companiesRef = ref(db2, 'companies');
        const snapshot = await get(companiesRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const companyList = Object.values(data);
          setCompanies(companyList);
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (buyerInput === '') {
        setFilteredCompanies([]);
        setShowDropdown(false);
      } else {
        const lowerCaseInput = buyerInput.toLowerCase();
        const results = companies
          .filter(company => company.ime.toLowerCase().includes(lowerCaseInput))
          .slice(0, 5);
        setFilteredCompanies(results);
        setShowDropdown(true);
      }
    }, 300);

    return () => clearTimeout(handler);
  }, [buyerInput, companies]);

  // Function to generate OrderID
  const generateOrderID = (vendor, jurisdiction = '') => {
    if (!currentInvoice) return '';

    const yearLastTwoDigits = new Date().getFullYear().toString().slice(-2);
    const vendorFirstLetter = vendor.charAt(0).toUpperCase();
    const invoiceKey = `CurrentInvoice${jurisdiction}`;
    const currentInvoiceNumber = parseInt(currentInvoice[invoiceKey]) || '0';

    const jurisdictionSuffix = jurisdiction === 'EU' ? 'B' : '';

    return `${currentInvoiceNumber}${vendorFirstLetter}${yearLastTwoDigits}${jurisdictionSuffix}`;
  };

  // Function to get default OrderID
  const getDefaultOrderID = () => {
    if (!currentInvoice) return '';

    const jurisdiction = '';
    const yearLastTwoDigits = new Date().getFullYear().toString().slice(-2);
    const vendorFirstLetter = vendorDestination.charAt(0).toUpperCase();
    const invoiceKey = `CurrentInvoice${jurisdiction}`;
    const currentInvoiceNumber = currentInvoice[invoiceKey] || '1';

    return `${currentInvoiceNumber}${vendorFirstLetter}${yearLastTwoDigits}`;
  };

  const getFormattedDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    return `${day}-${month}-${year}`;  // Format as DD-MM-YYYY
  };

  const [newOrder, setNewOrder] = useState({
    OrderID: getDefaultOrderID(), // Use default OrderID
    OrderVendor: vendorDestination,
    OrderDate: getFormattedDate(),
    OriginationNumber: '',
    DateOfDelivery: null, // Default to null or a Date object
    DateOfPayment: null,   // Default to null or a Date object
    Buyer: '',
    Comment: '',
    Status: 'Processing',
    Jurisdiction: '',
    InvoiceLink: '',
    OtherLink: '',
    Advance: '0',
    Username: user.displayName,
  });

  useEffect(() => {
    // Update OrderID when Jurisdiction changes
    if (newOrder.Jurisdiction) {
      const updatedOrderID = generateOrderID(vendorDestination, newOrder.Jurisdiction);
      setNewOrder(prevState => ({
        ...prevState,
        OrderID: updatedOrderID
      }));
    }
  }, [newOrder.Jurisdiction, vendorDestination, currentInvoice]);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewOrder(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'Advance') {
      const formattedValue = parseFloat(value.replace(/,/g, '')).toLocaleString(undefined, { minimumFractionDigits: 2 });
      setNewOrder((prevState) => ({
        ...prevState,
        [name]: formattedValue,
      }));
    }
  };

  const handleJurisdictionChange = (e) => {
    const { value } = e.target;
    setNewOrder(prevState => ({
      ...prevState,
      Jurisdiction: value
    }));
  };

  const handleBuyerChange = (e) => {
    setBuyerInput(e.target.value);
  };

  const handleBuyerSelect = (company) => {
    setBuyerInput(company.ime);
    setNewOrder(prevState => ({
      ...prevState,
      Buyer: company.ime
    }));
    setShowDropdown(false);
  };

  const handleInvoiceDocumentUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(prev => ({ ...prev, invoice: true }));

    const storageRef = firebase.storage().ref();
    const invoiceDocRef = storageRef.child(`documents/${newOrder.OrderID}/${file.name}`);

    const uploadTask = invoiceDocRef.put(file);

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Invoice upload is ${progress}% done`);
      },
      (error) => {
        console.error('Error uploading invoice document:', error);
        setUploading(prev => ({ ...prev, invoice: false }));
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          setNewOrder(prevState => ({
            ...prevState,
            OtherLink: url
          }));
          setUploading(prev => ({ ...prev, invoice: false }));
        });
      }
    );
  };

  const handleDateChange = (date, field) => {
    setNewOrder(prevState => ({
      ...prevState,
      [field]: date // Store Date object
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (uploading.invoice || uploading.other) {
      alert('Please wait until all files are uploaded.');
      return;
    }

    const dbRef = firebase.database().ref('orders');
    const dbInvoice = firebase.database().ref('invoices');

    const {
      OrderID,
      OrderVendor,
      Jurisdiction,
      InvoiceLink: InvoiceDocumentURL,
      OtherLink: OtherDocumentURL,
      ...orderData
    } = newOrder;

    const firebaseID = OrderID;

    const updatedOrderData = {
      ...orderData,
      OrderID,
      OrderVendor,
      Jurisdiction,
      InvoiceLink: InvoiceDocumentURL,
      OtherLink: OtherDocumentURL,
      // Convert dates to DD-MM-YYYY format for saving to database
      DateOfDelivery: formatDate(newOrder.DateOfDelivery),
      DateOfPayment: formatDate(newOrder.DateOfPayment),
      Username: user.displayName,
      firebaseID,
    };

    dbRef.child(firebaseID).set(updatedOrderData)
      .then(() => {
        const invoiceKey = `CurrentInvoice${newOrder.Jurisdiction}`;
        const currentInvoiceNumber = parseInt(currentInvoice[invoiceKey]) || 1;
        const newInvoiceNumber = currentInvoiceNumber + 1;

        return dbInvoice.child(vendorDestination).update({ [invoiceKey]: newInvoiceNumber.toString() });
      })
      .then(() => {
        console.log(`Invoice number updated successfully for ${OrderVendor} in ${Jurisdiction}`);


        setNewOrder({
          OrderID: getDefaultOrderID(),
          OrderVendor: vendorDestination,
          OrderDate: getFormattedDate(),
          OriginationNumber: '',
          DateOfDelivery: null,
          DateOfPayment: null,
          Buyer: '',
          Comment: '',
          Status: 'Processing',
          Jurisdiction: '',
          InvoiceLink: '',
          OtherLink: '',
          Advance: '0',
          Username: user.displayName,
        });
        setShowForm(false);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <button className="add-order-button" onClick={toggleForm}>
        Нова Нарачка
      </button>
      {showForm && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggleForm}>
              &times;
            </span>
            <form onSubmit={handleSubmit} className="form-container">
              <div className="form-group">
                <label className="label">Фирма:</label>
                <div className="jurisdiction-options">
                  <label>
                    <input
                      type="radio"
                      name="Jurisdiction"
                      value="MK"
                      checked={newOrder.Jurisdiction === 'MK'}
                      onChange={handleJurisdictionChange}
                    />
                    <span className="pill">MK</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="Jurisdiction"
                      value="EU"
                      checked={newOrder.Jurisdiction === 'EU'}
                      onChange={handleJurisdictionChange}
                    />
                    <span className="pill">EU</span>
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label className="label">Број на Нарачка:</label>
                <input
                  type="text"
                  name="OrderID"
                  value={newOrder.OrderID}
                  disabled
                  className="input"
                />
              </div>
              <div className="form-group">
                <label className="label">Датум на нарачка:</label>
                <input
                  type="text"
                  name="OrderDate"
                  value={newOrder.OrderDate}
                  disabled
                  className="input"
                />
              </div>
              <div className="form-group">
                <label className="label">Производител:</label>
                <input
                  type="text"
                  name="OrderVendor"
                  disabled
                  value={newOrder.OrderVendor}
                  className="input"
                />
              </div>
              {newOrder.Jurisdiction && (
                <>
                  <div className="form-group">
                  <label className="label">Купец:</label>
                  <div className="dropdown">
                      <input
                        type="text"
                        name="Buyer"
                        value={buyerInput}
                        onChange={handleBuyerChange}
                        onFocus={() => setShowDropdown(true)}
                        onBlur={() => setTimeout(() => setShowDropdown(false), 200)} // Delay to allow click on dropdown item
                        className="input"
                      />
                      {showDropdown && filteredCompanies.length > 0 && (
                        <ul className="dropdown-menu">
                          {filteredCompanies.map((company) => (
                            <li
                              key={company.id}
                              onClick={() => handleBuyerSelect(company)}
                              className="dropdown-item"
                            >
                              {company.ime}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  {userRole === "admin" && (
                    <>
                      <div className="form-group">
                        <label className="label">ОА Број:</label>
                        <input
                          type="text"
                          name="OriginationNumber"
                          value={newOrder.OriginationNumber}
                          onChange={handleChange}
                          className="input"
                        />
                      </div>
                      <div className="form-group">
                        <label className="label">Датум на достава:</label>
                        <DatePicker
                          selected={newOrder.DateOfDelivery ? new Date(newOrder.DateOfDelivery) : null}
                          onChange={(date) => handleDateChange(date, 'DateOfDelivery')}
                          dateFormat="dd-MM-yyyy"
                          className="input"
                        />
                      </div>
                      <div className="form-group">
                        <label className="label">Датум на уплата:</label>
                        <DatePicker
                          selected={newOrder.DateOfPayment ? new Date(newOrder.DateOfPayment) : null}
                          onChange={(date) => handleDateChange(date, 'DateOfPayment')}
                          dateFormat="dd-MM-yyyy"
                          className="input"
                        />
                      </div>
                    </>
                  )}
                  <div className="form-group">
                    <label className="label">Коментар:</label>
                    <textarea
                      name="Comment"
                      value={newOrder.Comment}
                      onChange={handleChange}
                      className="input"
                    />
                  </div>
                  <div className="form-group">
                    <label className="label">Авансна Уплата (во Валута):</label>
                    <input
                      name="Advance"
                      onBlur={handleBlur}
                      value={newOrder.Advance}
                      onChange={handleChange}
                      className="input"
                    />
                  </div>
                  <div className="form-group">
                    <label className="label">Док. за нарачка:</label>
                    <input type="file" onChange={handleInvoiceDocumentUpload} />
                  </div>
                </>
              )}
              <div className="form-group">
                <label className="label">Одговорен:</label>
                <input
                  name="Comment"
                  disabled
                  value={newOrder.Username}
                  className="input"
                />
              </div>
              <div className="form-group">
                <label className="label">Статус:</label>
                <input
                  type="text"
                  name="Status"
                  style={{ color: 'green' }}
                  value={newOrder.Status}
                  disabled
                  className="input"
                />
              </div>
              <button
                type="submit"
                className="submit-button"
                disabled={!newOrder.Jurisdiction || uploading.invoice || uploading.other}
              >
                Внеси
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddOrderForm;
