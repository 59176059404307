/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import '../css/ordersTable.css';
import InvoiceIcon from '../Icons/InvoiceIcon.js';
import EditOrderForm from './EditOrderForm.js';
import GenerateInvoiceModal from './GenerateInvoiceModal.js'; // Import GenerateInvoiceModal
import CompanyInfoModal from './CompanyInfoModal'; // Import the CompanyInfoModal component
import { ref, get } from 'firebase/database';
import { db2 } from '../firebase.js';
import InvoiceMultipleModal from './GenerateInvoiceOAMultipleModal.js';
import EditOrderFormU from './EditOrderFormU.js';

const OrdersTable = ({ user, userRole, orders, vendor }) => {
  const [previewUrl, setPreviewUrl] = useState('');
  const [editingOrder, setEditingOrder] = useState(null);
  const [editingOrderU, setEditingOrderU] = useState(null);
  const [showGenerateInvoiceModal, setShowGenerateInvoiceModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [showCompanyInfoModal, setShowCompanyInfoModal] = useState(false);
  const [showMultipleInvoiceModal, setShowMultipleInvoiceModal] = useState(false);

  const handleEditOrder = (order) => {
    setEditingOrder(order);
  };

  const handleCloseModal = () => {
    setEditingOrder(null);
  };

  const handleEditOrderU = (order) => {
    setEditingOrderU(order);
  };

  const handleCloseModalU = () => {
    setEditingOrderU(null);
  };

  const handleGenerateInvoice = (order) => {
    setSelectedOrder(order);
    setShowGenerateInvoiceModal(true);
  };

  const handleMultipleInvoiceModal = (order) => {
    setSelectedOrder(order);
    setShowMultipleInvoiceModal(true);
  };

  const handleCloseMultipleInvoiceModal = () =>{
    setSelectedOrder(null);
    setShowMultipleInvoiceModal(false);
  }
  const handleCloseGenerateInvoiceModal = () => {
    setShowGenerateInvoiceModal(false);
    setSelectedOrder(null);
  };

  const handlePreviewInvoice = (invoiceLink) => {
    setPreviewUrl(invoiceLink);
  };

  const handleClosePreview = () => {
    setPreviewUrl('');
  };

  const handleOpenCompanyInfo = async (buyerName) => {
    try {
      const companiesRef = ref(db2, 'companies');
      const snapshot = await get(companiesRef);
      if (snapshot.exists()) {
        const companies = snapshot.val();
        const companyList = Object.values(companies);
        const foundCompany = companyList.find(company => company.ime === buyerName);
        if (foundCompany) {
          setCompanyInfo(foundCompany);
          setShowCompanyInfoModal(true);
        } else {
          console.log('Company not found');
        }
      } else {
        console.log('No data available');
      }
    } catch (error) {
      console.error('Error fetching company info:', error);
    }
  };

  return (
    <div className="orders-table-container">
      <h2>{vendor}</h2>
      {previewUrl && (
        <div className="invoice-preview">
          <div className="preview-content">
            <button className="close-button" onClick={handleClosePreview}>&times;</button>
            <iframe src={previewUrl} title="Invoice Preview" className="preview-frame"></iframe>
          </div>
        </div>
      )}
      <table className="orders-table orders-table-1">
        <thead>
          <tr>
            <th>Број на нарачка</th>
            <th>Датум - нарачка</th>
            <th>ОА Број</th>
            <th>д. Испорака</th>
            <th>д. Уплата</th>
            <th>Купец</th>
            <th>Коментари</th>
            <th>Док.</th>
            <th>Фирма</th>
            <th>Одговорно Лице</th>
            <th>Аванс Сума</th>
            {userRole === "admin" && <th>Подеси</th>}
          </tr>
        </thead>
        {orders.length > 0 ? (
          <tbody>
            {Object.keys(orders).map((firebaseId) => {
              const order = orders[firebaseId];
              return (
                <tr key={firebaseId}>
                  {userRole === "admin" ? (
                    <td>
                      <a className='order-link' href="#" onClick={() => handleEditOrder(order)}>
                        {order.OrderID}
                      </a>
                    </td>
                  ) : (
                    <td>
                      <a className='order-link' href="#" onClick={() => handleEditOrderU(order)}>
                        {order.OrderID}
                      </a>
                    </td>
                  )}
                  <td>{order.OrderDate}</td>
                  {userRole === "admin" ? (
                    <td>
                      <a className='order-link' href="#" onClick={() => handleMultipleInvoiceModal(order)}>
                        {order.OriginationNumber}
                      </a>
                    </td>
                  ) : (
                    <td>{order.OriginationNumber}</td>
                  )}
                  <td>{order.DateOfDelivery}</td>
                  <td>{order.DateOfPayment}</td>
                  <td>
                    <a className='order-link' href="#" onClick={() => handleOpenCompanyInfo(order.Buyer)}>
                      {order.Buyer}
                    </a>
                  </td>
                  <td>{order.Comment}</td>
                  <td>
                    {order.OtherLink !== '' && (
                      <button onClick={() => handlePreviewInvoice(order.OtherLink)}>
                        <InvoiceIcon />
                      </button>
                    )}
                  </td>
                  <td>{order.Jurisdiction}</td>
                  <td>{order.Username}</td>
                  <td>{order.Advance}</td>
                  {userRole === "admin" && (
                    <td>
                      <button onClick={() => handleGenerateInvoice(order)}>Фактурирај</button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <p>Нема Активни</p>
        )}
      </table>
      {editingOrder && (
        <EditOrderForm
          order={editingOrder}
          user={user}
          onClose={handleCloseModal}
        />
      )}
        {editingOrderU && (
        <EditOrderFormU
          order={editingOrderU}
          user={user}
          onClose={handleCloseModalU}
        />
      )}
      {showGenerateInvoiceModal && (
        <GenerateInvoiceModal
          currentOrderID={selectedOrder.OrderID}
          order={selectedOrder}
          onClose={handleCloseGenerateInvoiceModal}
        />
      )}
            {showMultipleInvoiceModal && (
        <InvoiceMultipleModal
        ordersOANum={selectedOrder.OriginationNumber}
          onClose={handleCloseMultipleInvoiceModal}
        />
      )}
      {showCompanyInfoModal && companyInfo && (
        <CompanyInfoModal
          company={companyInfo}
          onClose={() => setShowCompanyInfoModal(false)}
        />
      )}
    </div>
  );
};

export default OrdersTable;
