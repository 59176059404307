// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { get, ref, onValue, set, update, remove, off, query, limitToFirst,orderByChild, startAt, endAt,equalTo } from 'firebase/database';

// Primary Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBSmq6V5c_hXm8FYiRlvcgI_zOORoGZ6rc",
  authDomain: "crm-somitek.firebaseapp.com",
  databaseURL: "https://crm-somitek-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "crm-somitek",
  storageBucket: "crm-somitek.appspot.com",
  messagingSenderId: "736436313222",
  appId: "1:736436313222:web:b4a4b25925dbb993d28507"
};


// Initialize Firebase apps
const app = initializeApp(firebaseConfig);

// Initialize services for the primary app
const auth = getAuth(app);
const db = getDatabase(app);
const functions = getFunctions(app);
const storage = getStorage(app);
const db2 = getDatabase(app, "https://crm-somitek-firmi.europe-west1.firebasedatabase.app/");


// Initialize services for the secondary app

// Export services
export { auth, db, db2, functions,orderByChild,equalTo, storage,limitToFirst, startAt, endAt, query, ref, onValue, set, update, get, remove, off };
