/* eslint-disable react/prop-types */
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element, userRole, user, requiredRole }) => {
  if (!user) {
    return <Navigate to="/signin" replace />;
  }

  if (requiredRole && userRole !== requiredRole) {
    return <Navigate to="/" replace />;
  }

  return element;
};

export default PrivateRoute;

