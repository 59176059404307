/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { getAuth, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import styles from '../css/ProfileSettings.module.css'; // Import the CSS module

const ProfileSettings = ({ user }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showPasswordFields, setShowPasswordFields] = useState(false);

  const handleChangePassword = () => {
    if (newPassword.trim() === '' || confirmNewPassword.trim() === '' || currentPassword.trim() === '') {
      setError('All password fields must be filled');
      return;
    }
  
    if (newPassword !== confirmNewPassword) {
      setError('New passwords do not match');
      return;
    }
  
    const auth = getAuth();
    const credential = EmailAuthProvider.credential(user.email, currentPassword);
  
    reauthenticateWithCredential(auth.currentUser, credential)
      .then(() => {
        return updatePassword(auth.currentUser, newPassword);
      })
      .then(() => {
        setSuccessMessage('Password updated successfully');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        setError(null);
        setShowPasswordFields(false);
      })
      .catch(error => {
        setError('Failed to update password');
        console.error(error);
      });
  };
  

  return (
    <div className={styles.profileSettingsPage}>
      <div className={styles.profileSettingsContainer}>
        <div className={styles.profileSettingsBox}>
          <h2>Profile Settings</h2>
          <FontAwesomeIcon icon={faUser} size="5x" color='#17a2b8'/>
          <p>{user.displayName || 'No Name'}</p>
          <p>Email: {user.email}</p>
          

          {error && <p style={{ color: 'red' }}>{error}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

          <button onClick={() => setShowPasswordFields(!showPasswordFields)}>
            Промени Лозинка
          </button>
          {showPasswordFields && (
            <div className={styles.inputGroup}>
              <label>Тековна Лозинка:</label>
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <label>Нова Лозинка:</label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <label>Потврди ја новата лозинка:</label>
              <input
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
              <button onClick={handleChangePassword}>Промени</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
