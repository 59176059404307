/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import firebase from '../firebaseOLD';
import '../css/editOrderForm.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const EditOrderForm = ({ order, onClose }) => {
  const [updatedOrder, setUpdatedOrder] = useState(order);
  const [uploading, setUploading] = useState({ invoice: false, other: false });

  useEffect(() => {
    setUpdatedOrder(order);
  }, [order]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedOrder((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'Advance') {
      const formattedValue = parseFloat(value.replace(/,/g, '')).toLocaleString(undefined, { minimumFractionDigits: 2 });
      setUpdatedOrder((prevState) => ({
        ...prevState,
        [name]: formattedValue,
      }));
    }
  };

  // Helper function to convert date string to Date object
  const parseDateString = (dateStr) => {
    const [day, month, year] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day); // months are 0-based in JS
  };

  // Helper function to format Date object to DD-MM-YYYY string
  const formatDateToString = (date) => {
    if (!date) return '';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleDateChange = (date, field) => {
    setUpdatedOrder(prevState => ({
      ...prevState,
      [field]: formatDateToString(date) // Convert Date object to string
    }));
  };

  const handleDocumentUpload = (e, type) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading((prev) => ({ ...prev, [type]: true }));

    const storageRef = firebase.storage().ref();
    const docRef = storageRef.child(`documents/${updatedOrder.OrderID}/${file.name}`);

    docRef
      .put(file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          setUpdatedOrder((prevState) => ({
            ...prevState,
            [`${type}Link`]: url,
          }));
          setUploading((prev) => ({ ...prev, [type]: false }));
        });
      })
      .catch(() => setUploading((prev) => ({ ...prev, [type]: false })));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (uploading.invoice || uploading.other) {
      alert('Please wait until all files are uploaded.');
      return;
    }

    const dbRef = firebase.database().ref('orders').child(updatedOrder.OrderID);

    dbRef
      .update(updatedOrder)
      .then(() => {
        console.log('Order updated successfully!');
        onClose();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <form onSubmit={handleSubmit} className="form-container">
          <div className="form-group">
            <label className="label">Број на нарачка:</label>
            <input
              type="text"
              name="OrderID"
              value={updatedOrder.OrderID}
              disabled
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Датум на нарачка:</label>
            <input
              type="text"
              name="OrderDate"
              value={updatedOrder.OrderDate}
              disabled
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Производител:</label>
            <input
              type="text"
              name="OrderVendor"
              value={updatedOrder.OrderVendor}
              disabled
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Купец:</label>
            <input
              type="text"
              name="Buyer"
              value={updatedOrder.Buyer}
              onChange={handleChange}
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">ОА број:</label>
            <input
              type="text"
              name="OriginationNumber"
              value={updatedOrder.OriginationNumber}
              onChange={handleChange}
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Датум на достава:</label>
            <DatePicker
              selected={updatedOrder.DateOfDelivery ? parseDateString(updatedOrder.DateOfDelivery) : null}
              onChange={(date) => handleDateChange(date, 'DateOfDelivery')}
              dateFormat="dd-MM-yyyy"
              className="input"
              placeholderText="Select delivery date"
            />
          </div>
          <div className="form-group">
            <label className="label">Датум на уплата:</label>
            <DatePicker
              selected={updatedOrder.DateOfPayment ? parseDateString(updatedOrder.DateOfPayment) : null}
              onChange={(date) => handleDateChange(date, 'DateOfPayment')}
              dateFormat="dd-MM-yyyy"
              className="input"
              placeholderText="Select payment date"
            />
          </div>
          <div className="form-group">
            <label className="label">Коментар:</label>
            <textarea
              name="Comment"
              value={updatedOrder.Comment}
              onChange={handleChange}
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Одговорен:</label>
            <input
              name="Username"
              value={updatedOrder.Username}
              disabled
              className="input"
            />
          </div>
          <div className="form-group">
                    <label className="label">Авансна Уплата (во Валута):</label>
                    <input
                      name="Advance"
                      onBlur={handleBlur}
                      value={updatedOrder.Advance}
                      onChange={handleChange}
                      className="input"
                    />
                  </div>
          <div className="form-group">
            <label className="label">Статус:</label>
            <select
              name="Status"
              value={updatedOrder.Status}
              onChange={handleChange}
              className="input"
            >
              <option value="Processing">Обработка</option>
              <option value="Invoiced">Фактурирано</option>
              <option value="Archived">Архивирај</option>
            </select>
          </div>
          <div className="form-group">
            <label className="label">Валута:</label>
            <input
              type="text"
              name="currency"
              value={updatedOrder.currency}
              onChange={handleChange}
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Сума:</label>
            <input
              type="text"
              name="totalSum"
              value={updatedOrder.totalSum}
              onChange={handleChange}
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Датум за наплата (DD-MM-YYYY):</label>
            <input
              type="text"
              name="dueDate"
              value={updatedOrder.dueDate}
              onChange={handleChange}
              placeholder="DD-MM-YYYY"
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Док. за нарачка:</label>
            {updatedOrder.OtherLink ? (
              <>
                <a href={updatedOrder.OtherLink} target="_blank" rel="noopener noreferrer">
                  Прегледај документ
                </a>
                <input type="file" onChange={(e) => handleDocumentUpload(e, 'Other')} />
              </>
            ) : (
              <input type="file" onChange={(e) => handleDocumentUpload(e, 'Other')} />
            )}
          </div>
          <div className="form-group">
            <label className="label">фактура:</label>
            {updatedOrder.InvoiceLink ? (
              <>
                <a href={updatedOrder.InvoiceLink} target="_blank" rel="noopener noreferrer">
                  Прегледај документ
                </a>
                <input type="file" onChange={(e) => handleDocumentUpload(e, 'Invoice')} />
              </>
            ) : (
              <input type="file" onChange={(e) => handleDocumentUpload(e, 'Invoice')} />
            )}
          </div>
          <div className="form-actions">
            <button type="submit" className="btn-save">
              Зачувај промени
            </button>
            <button type="button" onClick={onClose} className="btn-discard">
              Одфрли промени
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditOrderForm;
