/* eslint-disable react/prop-types */
// EventCard.js
import React from 'react';
import '../css/EventCard.css'; // Import styles

const EventCard = ({ event, onDetailsClick, backgroundColor }) => {
  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div className="event-card" style={{ backgroundColor }}>
      <h3>{event.companyName}</h3>
      <p>{formatDate(event.date)}</p>
      <p>{event.description}</p>
      <p>Одговорен: {event.createdBy}</p>
      <button onClick={onDetailsClick}>Детали</button>
    </div>
  );
};

export default EventCard;
