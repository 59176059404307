/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import firebase from '../firebaseOLD';
import { ref, get } from 'firebase/database';
import { db2 } from '../firebase';
import '../css/editOrderForm.css';
import 'react-datepicker/dist/react-datepicker.css';

const EditOrderFormU = ({ order, onClose }) => {
  const [updatedOrder, setUpdatedOrder] = useState(order);
  const [buyerInput, setBuyerInput] = useState(order.Buyer);
  const [uploading, setUploading] = useState({ invoice: false, other: false });
  const [showDropdown, setShowDropdown] = useState(false);
  const [companies, setCompanies] = useState([]);

  const [filteredCompanies, setFilteredCompanies] = useState([]);


  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companiesRef = ref(db2, 'companies');
        const snapshot = await get(companiesRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const companyList = Object.values(data);
          setCompanies(companyList);
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (buyerInput === '') {
        setFilteredCompanies([]);
        setShowDropdown(false);
      } else {
        const lowerCaseInput = buyerInput.toLowerCase();
        const results = companies
          .filter(company => company.ime.toLowerCase().includes(lowerCaseInput))
          .slice(0, 5);
        setFilteredCompanies(results);
        setShowDropdown(true);
      }
    }, 300);

    return () => clearTimeout(handler);
  }, [buyerInput, companies]);

  useEffect(() => {
    setUpdatedOrder(order);
  }, [order]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedOrder((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'Advance') {
      const formattedValue = parseFloat(value.replace(/,/g, '')).toLocaleString(undefined, { minimumFractionDigits: 2 });
      setUpdatedOrder((prevState) => ({
        ...prevState,
        [name]: formattedValue,
      }));
    }
  };

  const handleDocumentUpload = (e, type) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading((prev) => ({ ...prev, [type]: true }));

    const storageRef = firebase.storage().ref();
    const docRef = storageRef.child(`documents/${updatedOrder.OrderID}/${file.name}`);

    docRef
      .put(file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          setUpdatedOrder((prevState) => ({
            ...prevState,
            [`${type}Link`]: url,
          }));
          setUploading((prev) => ({ ...prev, [type]: false }));
        });
      })
      .catch(() => setUploading((prev) => ({ ...prev, [type]: false })));
  };

  const handleBuyerChange = (e) => {
    setBuyerInput(e.target.value);
  };

  const handleBuyerSelect = (company) => {
    setBuyerInput(company.ime);
    setUpdatedOrder(prevState => ({
      ...prevState,
      Buyer: company.ime
    }));
    setShowDropdown(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (uploading.invoice || uploading.other) {
      alert('Please wait until all files are uploaded.');
      return;
    }

    const dbRef = firebase.database().ref('orders').child(updatedOrder.OrderID);

    dbRef
      .update(updatedOrder)
      .then(() => {
        console.log('Order updated successfully!');
        onClose();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <form onSubmit={handleSubmit} className="form-container">
          <div className="form-group">
            <label className="label">Број на нарачка:</label>
            <input
              type="text"
              name="OrderID"
              value={updatedOrder.OrderID}
              disabled
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Датум на нарачка:</label>
            <input
              type="text"
              name="OrderDate"
              value={updatedOrder.OrderDate}
              disabled
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Производител:</label>
            <input
              type="text"
              name="OrderVendor"
              value={updatedOrder.OrderVendor}
              disabled
              className="input"
            />
          </div>
          <div className="form-group">
                    <label className="label">Купец:</label>
                    <div className="dropdown">
                      <input
                        type="text"
                        name="Buyer"
                        value={buyerInput}
                        onChange={handleBuyerChange}
                        onFocus={() => setShowDropdown(true)}
                        onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
                        className="input"
                      />
                      {showDropdown && filteredCompanies.length > 0 && (
                        <ul className="dropdown-menu">
                          {filteredCompanies.map((company) => (
                            <li
                              key={company.id}
                              onClick={() => handleBuyerSelect(company)}
                              className="dropdown-item"
                            >
                              {company.ime}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
          <div className="form-group">
            <label className="label">Коментар:</label>
            <textarea
              name="Comment"
              value={updatedOrder.Comment}
              onChange={handleChange}
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Одговорен:</label>
            <input
              name="Username"
              value={updatedOrder.Username}
              disabled
              className="input"
            />
          </div>
          <div className="form-group">
                    <label className="label">Авансна Уплата (во Валута):</label>
                    <input
                      name="Advance"
                      onBlur={handleBlur}
                      value={updatedOrder.Advance}
                      onChange={handleChange}
                      className="input"
                    />
                  </div>
          <div className="form-group">
            <label className="label">Док. за нарачка:</label>
            {updatedOrder.OtherLink ? (
              <>
                <a href={updatedOrder.OtherLink} target="_blank" rel="noopener noreferrer">
                  Прегледај документ
                </a>
                <input type="file" onChange={(e) => handleDocumentUpload(e, 'Other')} />
              </>
            ) : (
              <input type="file" onChange={(e) => handleDocumentUpload(e, 'Other')} />
            )}
          </div>
          <div className="form-actions">
            <button type="submit" className="btn-save">
              Зачувај промени
            </button>
            <button type="button" onClick={onClose} className="btn-discard">
              Одфрли промени
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditOrderFormU;
