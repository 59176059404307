import React, { useState, useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase.js';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import styles from '../css/AdminUsers.module.css'; // Import CSS module
import CreateUserPage from './CreateUserPage.js';

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState({});
  const [newRoles, setNewRoles] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const auth = getAuth();
  const db = getDatabase();

  useEffect(() => {
    const fetchUsers = async () => {
      const listUsers = httpsCallable(functions, 'listUsers');
      try {
        const result = await listUsers();
        const userList = result.data.users;
        setUsers(userList);

        const rolesRef = ref(db, 'users');
        const handleRolesData = (snapshot) => {
          const data = snapshot.val() || {};
          setRoles(data);
          setNewRoles(data);
          setLoading(false);
        };

        onValue(rolesRef, handleRolesData, (error) => {
          setError(error);
          setLoading(false);
        });

        return () => off(rolesRef, 'value', handleRolesData);

      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [db, auth]);

  const handleRoleChange = (uid, newRole) => {
    setNewRoles((prevRoles) => ({
      ...prevRoles,
      [uid]: newRole
    }));
  };

  const handleUpdateRole = async (uid, role) => {
    const updateRole = httpsCallable(functions, 'updateUserRole');
    try {
      await updateRole({ uid, role });
      alert('Role updated successfully');
    } catch (error) {
      alert('Error updating role: ' + error.message);
    }
  };

  const handleDeleteUser = async (uid) => {
    if (window.confirm('Дали сте сигурни дека сакате да го одстраните корисникот?')) {
      const deleteUser = httpsCallable(functions, 'deleteUser');
      try {
        await deleteUser({ uid });
        alert('User deleted successfully');
      } catch (error) {
        alert('Error deleting user: ' + error.message);
      }
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className={styles.container}>
      <CreateUserPage/>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Име</th>
            <th>Email</th>
            <th>Улога</th>
            <th>Промени Улога</th>
            <th>Промени Улога</th>
            <th>Одстрани</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            const userRole = roles[user.uid]?.role || 'undefined';
            const newRole = newRoles[user.uid] || 'undefined';

            // Ensure userRole and newRole are strings
            const displayUserRole = typeof userRole === 'string' ? userRole : 'undefined';
            const displayNewRole = typeof newRole === 'string' ? newRole : 'undefined';

            return (
              <tr key={user.uid}>
                <td>{user.displayName || 'No Name'}</td>
                <td>{user.email || 'No Email'}</td>
                <td>{displayUserRole}</td>
                <td>
                  <select
                    value={displayNewRole}
                    onChange={(e) => handleRoleChange(user.uid, e.target.value)}
                    className={styles.select}
                  >
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                    <option value="undefined">Undefined</option>
                  </select>
                </td>
                <td>
                  <button
                    onClick={() => handleUpdateRole(user.uid, displayNewRole)}
                    className={styles.button}
                  >
                    Промени
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => handleDeleteUser(user.uid)}
                    className={styles.button}
                  >
                    Одстрани корисник
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AdminUsers;
