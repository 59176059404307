/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import firebase from '../firebaseOLD';

const InvoicingMultipleCard = ({ firebaseId,isSubmitting, onSubmitStatus }) => {
    const [updatedOrder, setUpdatedOrder] = useState({
        totalSum: '',
        dueDate: '',
        currency: 'MK-MKD',
        invoiceNumber: '',
        InvoiceLink: '',
        Buyer:'',
        Status:'',
        OtherLink:'',
    });
    const [uploading, setUploading] = useState(false);
    const [errors, setErrors] = useState({});
    const [currentInvoiceNumber, setCurrentInvoiceNumber] = useState(0);
    const [currentInvoiceNumberEU, setCurrentInvoiceNumberEU] = useState(0);
    const [jurisdiction, setJurisdiction] = useState('');
    const [orderStatus, setOrderStatus] = useState(false);


    useEffect(() => {
        const today = formatDate(new Date());
        setUpdatedOrder((prevState) => ({
          ...prevState,
          dueDate: today,
        }));
    
        const fetchOrderDetails = async () => {
            const orderRef = firebase.database().ref(`orders/${firebaseId}`);
            const snapshot = await orderRef.once('value');
            const orderData = snapshot.val();
            if (orderData) {
              setJurisdiction(orderData.Jurisdiction || '');
              if (orderData.Status !== "Processing"){
                setOrderStatus(true);
              }
      
              const invoiceCounterRef = firebase.database().ref('invoiceCounter');
              const counterSnapshot = await invoiceCounterRef.once('value');
              const currentNumber = parseInt(counterSnapshot.val()) || 0;
      
              const invoiceCounterEURef = firebase.database().ref('invoiceCounterEU');
              const counterEUSnapshot = await invoiceCounterEURef.once('value');
              const currentNumberEU = parseInt(counterEUSnapshot.val()) || 0;
      
              setCurrentInvoiceNumber(currentNumber);
              setCurrentInvoiceNumberEU(currentNumberEU);
              setUpdatedOrder((prevState) => ({
                ...prevState,
                invoiceNumber: generateInvoiceNumber(orderData.Jurisdiction === 'EU' ? currentNumberEU : currentNumber, orderData.Jurisdiction === 'EU'),
                totalSum: orderData.totalSum,
                currency: orderData.currency,
                InvoiceLink: orderData.InvoiceLink,
                Buyer: orderData.Buyer,
                OtherLink: orderData.OtherLink,
                Status: orderData.Status,
              }));
            }
          };
      
          fetchOrderDetails();

    }, [firebaseId]);

    const formatDate = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const year = d.getFullYear();
        return `${day}-${month}-${year}`;
      };
      
  const generateInvoiceNumber = (currentNumber, isEU) => {
    const yearLastTwoDigits = new Date().getFullYear().toString().slice(-2);
    return `${String(currentNumber).padStart(3, '0')}\\${yearLastTwoDigits}${isEU ? 'B' : ''}`;
  };

  useEffect(() => {
    setUpdatedOrder((prevState) => ({
      ...prevState,
      invoiceNumber: generateInvoiceNumber(jurisdiction === 'EU' ? currentInvoiceNumberEU : currentInvoiceNumber, jurisdiction === 'EU'),
    }));
  }, [currentInvoiceNumber, currentInvoiceNumberEU, jurisdiction]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUpdatedOrder((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleDateValidation = () => {
        const [day, month, year] = updatedOrder.dueDate.split('-');
        const isValidDate = day && month && year &&
          day.length === 2 && month.length === 2 && year.length === 4 &&
          !isNaN(new Date(`${year}-${month}-${day}`).getTime());
    
        if (!isValidDate) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            dueDate: 'Invalid date format. Please enter a date in DD-MM-YYYY format.',
          }));
          return false;
        }
    
        setErrors((prevErrors) => ({
          ...prevErrors,
          dueDate: '',
        }));
        return true;
      };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name === 'totalSum') {
            const formattedValue = parseFloat(value.replace(/,/g, '')).toLocaleString(undefined, { minimumFractionDigits: 2 });
            setUpdatedOrder((prevState) => ({
                ...prevState,
                [name]: formattedValue,
            }));
        }
    };

    const handleDocumentUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;
    
        setUploading(true);
    
        const storageRef = firebase.storage().ref();
        const docRef = storageRef.child(`fakturi/${firebaseId}/${updatedOrder.invoiceNumber}`);
    
        docRef
            .put(file)
            .then((snapshot) => snapshot.ref.getDownloadURL())
            .then((url) => {
                setUpdatedOrder((prevState) => ({
                    ...prevState,
                    InvoiceLink: url,
                }));
                setUploading(false);
            })
            .catch(() => setUploading(false));
    };

    const incrementInvoiceNumber = () => {
        if (jurisdiction === 'EU') {
          setCurrentInvoiceNumberEU((prev) => prev + 1);
        } else {
          setCurrentInvoiceNumber((prev) => prev + 1);
        }
      };
    
      const decrementInvoiceNumber = () => {
        if (jurisdiction === 'EU') {
          setCurrentInvoiceNumberEU((prev) => Math.max(prev - 1, 0));
        } else {
          setCurrentInvoiceNumber((prev) => Math.max(prev - 1, 0));
        }
      };

    const handleSubmit = async () => {
        if (uploading && isSubmitting) {
            alert('Please wait until all files are uploaded.');
            return;
        }
        if (uploading.other && isSubmitting) {
            alert('Please wait until all files are uploaded.');
            return;
          }
      
          if (!handleDateValidation() && isSubmitting) {
            alert('Please fix the errors before submitting.');
            return;
          }
          if(updatedOrder.InvoiceLink === '' || updatedOrder.totalSum ===''){
            alert("missing elements");
            onSubmitStatus(firebaseId, false);
            return;
          }
      

          if (!orderStatus){
            const invoiceCounterEURef = firebase.database().ref('invoiceCounterEU');
            const invoiceCounterRef = firebase.database().ref('invoiceCounter');
            const counterEUSnapshot = await invoiceCounterEURef.once('value');
            const counterSnapshot = await invoiceCounterRef.once('value');
            const oldNumEu = parseInt(counterEUSnapshot.val()) || 0;
            const oldNum = parseInt(counterSnapshot.val()) || 0;


            if (jurisdiction === 'EU') {
                if (currentInvoiceNumberEU > oldNumEu ){
                await invoiceCounterEURef.set(currentInvoiceNumberEU + 1);
                }
              } else {
                if (currentInvoiceNumber > oldNum ){
                await invoiceCounterRef.set(currentInvoiceNumber + 1);
                }
              }
        const orderRef = firebase.database().ref(`orders/${firebaseId}`);
        await orderRef.update({
            Status: 'Invoiced',
            invoiceNumber: updatedOrder.invoiceNumber,
            currency: updatedOrder.currency,
            dueDate: updatedOrder.dueDate,
            totalSum: updatedOrder.totalSum,
            InvoiceLink: updatedOrder.InvoiceLink,
        });
    }

        // Provide status to parent
        onSubmitStatus(firebaseId, true);
    };

    useEffect(() => {
        if (isSubmitting) {
            handleSubmit();
        }
    }, [isSubmitting]);

    return (
        <tr>
            <td>{firebaseId}</td>
            <td>                
                {(updatedOrder.OtherLink) && (
                <a href={updatedOrder.OtherLink} target="_blank" rel="noopener noreferrer">
                  Прегледај Док.
                </a>
                )}
                </td>
            <td>{updatedOrder.Buyer}</td>
            <td>
                <div className="invoice-number-container">
                <input
                    type="text"
                    name="invoiceNumber"
                    value={updatedOrder.invoiceNumber}
                    disabled
                    className="input"
                />
                {!orderStatus &&(
<>
                <button type="button" onClick={decrementInvoiceNumber}>-</button>
                <button type="button" onClick={incrementInvoiceNumber}>+</button>
                </>
                )}


                </div>
            </td>
            <td>
                <input
                    type="text"
                    name="totalSum"
                    value={updatedOrder.totalSum}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input"
                    disabled={orderStatus}
                    required
                />
            </td>
            <td>
                <select
                    name="currency"
                    value={updatedOrder.currency}
                    onChange={handleChange}
                    className="input"
                    disabled={orderStatus}
                    required
                >
                    <option value="MK-MKD">MK-MKD</option>
                    <option value="MK-EUR">MK-EUR</option>
                    <option value="EU-EUR">EU-EUR</option>
                </select>
            </td>
            <td>
                <input
                    type="text"
                    name="dueDate"
                    value={updatedOrder.dueDate}
                    onChange={handleChange}
                    placeholder="DD-MM-YYYY"
                    className="input"
                    disabled={orderStatus}
                    required
                />
                {errors.dueDate && <div className="error-message">{errors.dueDate}</div>}
            </td>
            <td>
            {updatedOrder.InvoiceLink ? (
              <>
                <a href={updatedOrder.InvoiceLink} target="_blank" rel="noopener noreferrer">
                  Прегледај
                </a>
                <input type="file" disabled={orderStatus} onChange={(e) => handleDocumentUpload(e, 'Other')} />
              </>
            ) : (
              <input type="file" required onChange={(e) => handleDocumentUpload(e, 'Other')} />
            )}
            </td>
        </tr>
    );
};

export default InvoicingMultipleCard;
