// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/database";
import "firebase/compat/storage";
import 'firebase/database';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBSmq6V5c_hXm8FYiRlvcgI_zOORoGZ6rc",
  authDomain: "crm-somitek.firebaseapp.com",
  databaseURL: "https://crm-somitek-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "crm-somitek",
  storageBucket: "crm-somitek.appspot.com",
  messagingSenderId: "736436313222",
  appId: "1:736436313222:web:b4a4b25925dbb993d28507"
};

// Initialize Firebase
if (!firebase.apps.length) {
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
  }


export const auth = firebase.auth(); 
export const db = firebase.database();
export default firebase;

