/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import firebase from '../firebaseOLD';
import '../css/Archive.css'; // Include the CSS file
import OrdersTableArchive from '../elements/ordersTableArchive';

function Archive({ userRole = '' }) {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [buyerSearchTerm, setBuyerSearchTerm] = useState('');
  const [commentSearchTerm, setCommentSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [sortBy, setSortBy] = useState('date');
  const [selectedVendor, setSelectedVendor] = useState('all');
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });

  useEffect(() => {
    // Set default date range to one year ago to today
    const today = new Date();
    const oneYearAgo = new Date(today.setFullYear(today.getFullYear() - 1));
    
    // Format dates as DD-MM-YYYY
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    setDateRange({
      startDate: formatDate(oneYearAgo),
      endDate: formatDate(new Date())
    });
  }, []);

  useEffect(() => {
    const dbRef = firebase.database().ref('/orders');

    // Fetch orders and update state
    dbRef.on('value', snapshot => {
      const ordersData = snapshot.val();
      if (ordersData) {
        const ordersArray = Object.keys(ordersData).map(key => ({
          OrderID: key,
          ...ordersData[key]
        }));
        setOrders(ordersArray);
        setFilteredOrders(ordersArray);
      }
    });

    // Clean up listeners on component unmount
    return () => {
      dbRef.off();
    };
  }, []);

  useEffect(() => {
    const parseDate = (dateStr) => {
      if (!dateStr) return new Date(0); // Return a default date for missing or invalid dates
      const [day, month, year] = dateStr.split('-').map(Number);
      return new Date(year, month - 1, day);
    };

    let updatedOrders = [...orders];

    // Filter by vendor
    if (selectedVendor !== 'all') {
      updatedOrders = updatedOrders.filter(order => order.OrderVendor === selectedVendor);
    }

    // Filter by status
    if (filterStatus !== 'all') {
      updatedOrders = updatedOrders.filter(order => order.Status === filterStatus);
    }

    // Search by order number
    if (searchTerm) {
      updatedOrders = updatedOrders.filter(order =>
        order.OrderID.includes(searchTerm)
      );
    }

    // Search by buyer name
    if (buyerSearchTerm) {
      updatedOrders = updatedOrders.filter(order =>
        order.Buyer.toLowerCase().includes(buyerSearchTerm.toLowerCase())
      );
    }

    // Search by comments
    if (commentSearchTerm) {
      updatedOrders = updatedOrders.filter(order =>
        order.Comment.toLowerCase().includes(commentSearchTerm.toLowerCase())
      );
    }

    // Filter by date range
    const start = parseDate(dateRange.startDate);
    const end = parseDate(dateRange.endDate);

    updatedOrders = updatedOrders.filter(order => {
      const orderDate = parseDate(order.OrderDate);
      return orderDate >= start && orderDate <= end;
    });

    // Sort by selected criteria
    updatedOrders.sort((a, b) => {
      if (!a.OrderDate || !b.OrderDate) return 0; // Handle missing dates
      if (sortBy === 'date') {
        return parseDate(b.OrderDate) - parseDate(a.OrderDate);
      } else if (sortBy === 'vendor') {
        return a.OrderVendor.localeCompare(b.OrderVendor);
      } else if (sortBy === 'buyer') {
        return a.Buyer.localeCompare(b.Buyer);
      } else {
        return 0;
      }
    });

    setFilteredOrders(updatedOrders);
  }, [searchTerm, buyerSearchTerm, commentSearchTerm, filterStatus, sortBy, selectedVendor, dateRange, orders]);

  return (
    <div className="archive-container">
      <h1>Архива</h1>
      <div className="archive-controls">
        <div className="search-controls">
          <input
            type="text"
            placeholder="Бр. на нарачка..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <input
            type="text"
            placeholder="Купец..."
            value={buyerSearchTerm}
            onChange={(e) => setBuyerSearchTerm(e.target.value)}
          />
          <input
            type="text"
            placeholder="Коментар..."
            value={commentSearchTerm}
            onChange={(e) => setCommentSearchTerm(e.target.value)}
          />
        </div>
        <div className="filter-controls">
          <select
            value={selectedVendor}
            onChange={(e) => setSelectedVendor(e.target.value)}
          >
            <option value="all">Сите Производители</option>
            <option value="Donaldson">Donaldson</option>
            <option value="Euroventilatori">Euroventilatori</option>
          </select>
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">Сите Статуси</option>
            <option value="Processing">Во Обработка</option>
            <option value="Invoiced">Фактурирани</option>
            <option value="Archived">Архивирани</option>
          </select>
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="date">подреди по датум</option>
            <option value="vendor">Подреди по производител</option>
            <option value="buyer">Подреди по купец</option>
          </select>
        </div>
        <div className="date-range-controls">
          <input
            type="text"
            placeholder="Start Date (DD-MM-YYYY)"
            value={dateRange.startDate}
            onChange={(e) => setDateRange(prev => ({ ...prev, startDate: e.target.value }))}
          />
          <input
            type="text"
            placeholder="End Date (DD-MM-YYYY)"
            value={dateRange.endDate}
            onChange={(e) => setDateRange(prev => ({ ...prev, endDate: e.target.value }))}
          />
        </div>
      </div>
      <OrdersTableArchive orders={filteredOrders} userRole={userRole} />
    </div>
  );
}

export default Archive;
