import React from 'react';
import PropTypes from 'prop-types';
import '../css/CompanyDetail.css'; // Import the CSS file for styling

const CompanyDetail = ({ title, content }) => {
  return (
    <div className="company-detail-row">
      <div className="company-detail-title">{title}:</div>
      <div className="company-detail-content">{content || 'N/A'}</div>
    </div>
  );
};

CompanyDetail.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node, // Changed to node to handle elements like links
};

export default CompanyDetail;
