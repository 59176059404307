/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import firebase from '../firebaseOLD';
import OrdersTable from '../elements/ordersTable';
import OrdersTableOthers from '../elements/ordersTableOthers';
import AddOrderForm from '../pages/addOrderForm';
import AddOrderFormOthers from '../pages/addOrderFormOthers';

function TableHome({ userRole, user, userName = '' }) {
  const [donaldsonData, setDonaldsonData] = useState([]);
  const [euroventilatoriData, setEuroventilatoriData] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [currentInvoices, setCurrentInvoices] = useState({
    Donaldson: { CurrentInvoiceEU: "0", CurrentInvoiceMK: "0" },
    Euroventilatori: { CurrentInvoiceEU: "0", CurrentInvoiceMK: "0" },
    Other: { CurrentInvoiceEU: "0", CurrentInvoiceMK: "0" }
  });

  useEffect(() => {
    const dbRef = firebase.database().ref('/orders');
    const dbInvoice = firebase.database().ref('/invoices');

    // Function to fetch and update invoice numbers
    const fetchAndUpdateInvoices = () => {
      dbInvoice.on('value', snapshot => {
        const invoicesData = snapshot.val();
        if (invoicesData) {
          const updatedInvoices = {};
          Object.keys(invoicesData).forEach(key => {
            const invoice = invoicesData[key];
            updatedInvoices[key] = {
              CurrentInvoiceEU: invoice.CurrentInvoiceEU || "0",
              CurrentInvoiceMK: invoice.CurrentInvoiceMK || "0",
            };
          });
          setCurrentInvoices(prevState => ({
            ...prevState,
            ...updatedInvoices
          }));
        }
      });
    };

    // Function to fetch orders for each vendor and listen for changes
    const fetchOrdersAndListen = (vendor, setData) => {
      dbRef.orderByChild('OrderVendor').equalTo(vendor).on('value', snapshot => {
        const orders = snapshot.val();
        if (orders) {
          const ordersArray = Object.keys(orders)
            .filter(key => orders[key].Status === 'Processing')
            .map(key => ({
              OrderID: key,
              ...orders[key]
            }));
          setData(ordersArray);
        } else {
          setData([]);
        }
      });
    };

    // Fetch and listen for Donaldson orders
    fetchOrdersAndListen('Donaldson', setDonaldsonData);

    // Fetch and listen for Euroventilatori orders
    fetchOrdersAndListen('Euroventilatori', setEuroventilatoriData);

    // Fetch and listen for Other orders
    dbRef.orderByChild('OrderVendor').on('value', snapshot => {
      const orders = snapshot.val();
      if (orders) {
        const filteredOrders = Object.keys(orders)
          .filter(key => {
            const order = orders[key];
            return order.OrderVendor !== 'Euroventilatori' && order.OrderVendor !== 'Donaldson' && order.Status === 'Processing';
          })
          .map(key => ({
            OrderID: key,
            ...orders[key]
          }));
        setOtherData(filteredOrders);
      } else {
        setOtherData([]);
      }
    });

    // Call the function to fetch and update invoices
    fetchAndUpdateInvoices();

    // Clean up listeners when component unmounts
    return () => {
      dbRef.off(); // Turn off all listeners on '/orders'
      dbInvoice.off(); // Turn off all listeners on '/invoices'
    };

  }, []); // Empty dependency array ensures useEffect runs only once on component mount

  // Conditional rendering based on user role
  if (userRole !== 'admin' && userRole !== 'user') {
    return <h1 color="red">Неавторизиран пристап</h1>;
  }

  return (
    <div>
      <h1>Тековни Нарачки</h1>
      <div>
        <OrdersTable user={user} userRole={userRole} vendor="Donaldson" orders={donaldsonData} />
        <AddOrderForm userRole={userRole} user={user} userName={userName} vendorDestination="Donaldson" currentInvoice={currentInvoices.Donaldson} />
      </div>

      <div>
        <OrdersTable user={user} userRole={userRole} vendor="Euroventilatori" orders={euroventilatoriData} />
        <AddOrderForm userRole={userRole} user={user} userName={userName} vendorDestination="Euroventilatori" currentInvoice={currentInvoices.Euroventilatori} />
      </div>

      <div>
        <OrdersTableOthers user={user} userRole={userRole} vendor="Други" orders={otherData} />
        <AddOrderFormOthers user={user} userName={userName} userRole={userRole} vendorDestination="Other" currentInvoice={currentInvoices.Other} />
      </div>
    </div>
  );
}

export default TableHome;
