/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { db2, ref, set } from '../firebase';
import '../css/EventModal.css'; // Import styles
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the DatePicker styles

const AddEventModal = ({ companies, onClose, user }) => {
  const [formData, setFormData] = useState({
    companyName: '',
    description: '',
    date: '', // Initialize as an empty string
    isActive: true,
    createdBy: user?.displayName || '' // Ensure currentUser is defined
  });
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [buyerInput, setBuyerInput] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'companyName') {
      setBuyerInput(value); // Update input value for dropdown filter
      setSuggestions(
        companies
          .filter(company => company.ime?.toLowerCase().includes(value.toLowerCase())) // Filter based on 'ime'
          .slice(0, 5)
      );
    }
  };

  const handleBuyerChange = (e) => {
    setBuyerInput(e.target.value);
    handleChange(e);
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? formatDate(date) : '';
    setFormData({ ...formData, date: formattedDate });
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleBuyerSelect = (company) => {
    if (company?.ime) { // Ensure company has 'ime' property
      setFormData({ ...formData, companyName: company.ime });
      setBuyerInput(company.ime);
      setSuggestions([]);
    }
  };

  const handleSave = async () => {
    try {
      const newEventRef = ref(db2, 'events/' + new Date().getTime()); // Use a timestamp or UUID for a new event
      await set(newEventRef, formData);
      onClose();
    } catch (error) {
      console.error('Error adding event:', error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content-container">
        <span className="modal-close-button" onClick={onClose}>&times;</span>
        <h2 className="modal-title">Нова Активност</h2>
        <div className="modal-form-container">
          <div className="form-group">
            <label className="label">Фрима:</label>
            <div className="dropdown">
              <input
                type="text"
                name="companyName"
                value={buyerInput}
                onChange={handleBuyerChange}
                onFocus={() => setShowDropdown(true)}
                onBlur={() => setTimeout(() => setShowDropdown(false), 200)} // Delay to allow click on dropdown item
                className="input"
              />
              {showDropdown && suggestions.length > 0 && (
                <ul className="dropdown-menu">
                  {suggestions.map((company) => (
                    <li
                      key={company.id}
                      onClick={() => handleBuyerSelect(company)}
                      className="dropdown-item"
                    >
                      {company.ime}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="form-group">
            <label className="label">Опис:</label>
            <textarea
              className="modal-textarea"
              name="description"
              value={formData.description}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="form-group">
            <label className="label">Предвиден Датум:</label>
            <DatePicker
              selected={formData.date ? new Date(formData.date) : null} // Convert string to Date object
              onChange={handleDateChange}
              dateFormat="dd-MM-yyyy"
              className="input"
              placeholderText="датум до"
              popperPlacement='bottom-start'
            />
          </div>
          <div className="form-group">
            <label className="label">Одговорен:</label>
            <input
              className="input"
              type="text"
              name="createdBy"
              disabled
              value={formData.createdBy}
              onChange={handleChange}
            />
          </div>
          <button className="modal-save-button" onClick={handleSave}>Зачувај</button>
        </div>
      </div>
    </div>
  );
};

export default AddEventModal;
