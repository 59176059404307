import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { auth } from '../firebaseOLD'; // Update with correct import
import styles from '../css/loginPage.module.css'; // Import CSS module

const SignInPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await auth.signInWithEmailAndPassword(email, password);
      // Redirect or update state after successful sign-in
    } catch (error) {
      setError(error.message);
    }
  };


  return (
    <div className={styles.signInContainer}>
      <div className={styles.signInCard}>
        <h2 className={styles.signInTitle}>Sign In</h2>
        <form onSubmit={handleSignIn}>
          <div className={styles.inputGroup}>
            <input
              type="email"
              className={styles.signInInput}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={styles.inputGroup}>
            <input
              type="password"
              className={styles.signInInput}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className={styles.signInButton}>Sign In</button>
          <NavLink to="/forgot-password" exact activeClassName={styles.forgotPasswordButton}>
          Forgot Password ?
              </NavLink>
        </form>
        {error && <p className={styles.errorMessage}>{error}</p>}
      </div>
    </div>
  );
};

export default SignInPage;