/* eslint-disable react/prop-types */
import React from 'react';
import '../css/CompanyInfoModal.css'; // Add styles for modal
import CompanyDetail from './CompanyDetail';

const CompanyInfoModal = ({ company, onClose })=> {
  return (
    <div className="company-info-modal">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>Детали за фирма</h2>
        <h3>{company.ime}</h3>
        <CompanyDetail title="Контакт" content={company.kontakt} />
            <CompanyDetail title="Адреса" content={company.adresa} />
            <CompanyDetail title="Web страна" content={<a href={company.web} target="_blank" rel="noopener noreferrer">{company.web}</a>} />
            <CompanyDetail title="Интерес" content={company.interes} />
            <CompanyDetail title="Соработка" content={company.sorabotka} />
            <CompanyDetail title="Опрема" content={company.oprema} />
            <CompanyDetail title="Локација" content={company.google_location} />
            <CompanyDetail title="Држава" content={company.drzava_ime} />
            <CompanyDetail title="Град" content={company.grad_ime} />
            <CompanyDetail title="Индустрија" content={company.industrija_naziv} />
            <CompanyDetail title="Регион" content={company.region_ime} />
      </div>
    </div>
  );
};

export default CompanyInfoModal;
