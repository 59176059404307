import React, { useState, useEffect } from 'react';
import { db } from '../firebaseOLD';
import styles from '../css/AdminInvoices.module.css'; // Import CSS module

const AdminInvoices = () => {
  const [invoices, setInvoices] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updatedInvoices, setUpdatedInvoices] = useState({});
  const [invoiceCounter, setInvoiceCounter] = useState(0);
  const [invoiceCounterEU, setInvoiceCounterEU] = useState(0);
  const [updatedInvoiceCounter, setUpdatedInvoiceCounter] = useState(null);
  const [updatedInvoiceCounterEU, setUpdatedInvoiceCounterEU] = useState(null);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const snapshot = await db.ref('invoices').once('value');
        const countersSnapshot = await db.ref().once('value');
        setInvoices(snapshot.val());
        setInvoiceCounter(countersSnapshot.val().invoiceCounter);
        setInvoiceCounterEU(countersSnapshot.val().invoiceCounterEU);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchInvoices();
  }, []);

  const handleInputChange = (vendor, invoiceKey, newInvoiceNumber) => {
    setUpdatedInvoices((prev) => ({
      ...prev,
      [vendor]: {
        ...prev[vendor],
        [invoiceKey]: newInvoiceNumber,
      },
    }));
  };

  const handleCounterChange = (newCounter, isEU) => {
    if (isEU) {
      setUpdatedInvoiceCounterEU(newCounter);
    } else {
      setUpdatedInvoiceCounter(newCounter);
    }
  };

  const handleSubmit = async () => {
    const updates = {};
    Object.keys(updatedInvoices).forEach((vendor) => {
      Object.keys(updatedInvoices[vendor]).forEach((invoiceKey) => {
        updates[`invoices/${vendor}/${invoiceKey}`] = updatedInvoices[vendor][invoiceKey].toString();
      });
    });

    if (updatedInvoiceCounter !== null) {
      updates['invoiceCounter'] = updatedInvoiceCounter;
    }

    if (updatedInvoiceCounterEU !== null) {
      updates['invoiceCounterEU'] = updatedInvoiceCounterEU;
    }

    try {
      await db.ref().update(updates);
      alert('Invoice numbers and counters updated successfully');
    } catch (error) {
      alert('Error updating invoice number:', error.message);
    }
  };

  const resetYear = () => {
    const confirmed = window.confirm('Дали сте сигурни дека сакате да започнете нова фискална година? Овај чекор ги враќа сите бројачи на 1.');
    if (confirmed) {
      const updates = {};
      updates['invoiceCounter'] = 1;
      updates['invoiceCounterEU'] = 1;
      Object.keys(invoices).forEach((vendor) => {
        Object.keys(invoices[vendor]).forEach((invoiceKey) => {
          if (invoiceKey !== 'OrderVendor' && invoiceKey !== 'LastInvoiceYear') {
            updates[`invoices/${vendor}/${invoiceKey}`] = '1';
          }
        });
      });
      try {
        db.ref().update(updates);
        alert('All invoice numbers have been reset to 1');
      } catch (error) {
        alert('Error resetting invoice number:', error.message);
      }
    }
  };

  if (loading) return <p className={styles.message}>Loading...</p>;
  if (error) return <p className={styles.message}>Error: {error.message}</p>;

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Бројачи на фактура/нарачка</h2>
      <div className={styles.invoiceList}>
        {Object.keys(invoices).map((vendor) => (
          <div key={vendor} className={styles.invoiceVendor}>
            <h3 className={styles.vendorTitle}>{vendor}</h3>
            {Object.keys(invoices[vendor]).map(
              (invoiceKey) =>
                invoiceKey !== 'OrderVendor' && invoiceKey !== 'LastInvoiceYear' && (
                  <div key={invoiceKey} className={styles.invoiceItem}>
                    <label className={styles.label}>{invoiceKey}:</label>
                    <input
                      type="text"
                      defaultValue={invoices[vendor][invoiceKey]}
                      onChange={(e) => handleInputChange(vendor, invoiceKey, e.target.value)}
                      className={styles.input}
                    />
                  </div>
                )
            )}
          </div>
        ))}
        <div className={styles.invoiceVendor}>
          <h3 className={styles.vendorTitle}>Бројачи за фактури</h3>
          <div className={styles.invoiceItem}>
            <label className={styles.label}>тековна фактура МК:</label>
            <input
              type="number"
              defaultValue={invoiceCounter}
              onChange={(e) => handleCounterChange(e.target.value, false)}
              className={styles.input}
            />
          </div>
          <div className={styles.invoiceItem}>
            <label className={styles.label}>Тековна фактура ЕУ:</label>
            <input
              type="number"
              defaultValue={invoiceCounterEU}
              onChange={(e) => handleCounterChange(e.target.value, true)}
              className={styles.input}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <button onClick={handleSubmit} className={styles.button}>
          Промени
        </button>
        <button onClick={resetYear} className={styles.button}>
          Нова Фискална Година (сите на 1)
        </button>
      </div>
    </div>
  );
};

export default AdminInvoices;
