import React from 'react';
import AdminInvoices from '../elements/AdminInvoices';
import AdminUsers from '../elements/AdminUsers';
import styles from '../css/AdminPanel.module.css'; // Import the CSS module for AdminPanel

const AdminPanel = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Администрација</h1>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td className={styles.tableCell}>
              <AdminInvoices />
            </td>
            <td className={styles.tableCell}>
              <AdminUsers />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AdminPanel;
