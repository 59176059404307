import React, { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase.js';
import styles from '../css/CreateUserPage.module.css';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

const CreateUserPage = () => {
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserName, setNewUserName] = useState('');

  const auth = getAuth();

  const handleAddUser = async () => {
    const createUser = httpsCallable(functions, 'createUser');
    try {
      await createUser({ email: newUserEmail, displayName: newUserName });
      await sendPasswordResetEmail(auth, newUserEmail);

      alert('Корисникот беше успешно додаден. За активација потребно е да постави нова лозинка преку линкот испратен на: ' + newUserEmail);

      // Clear the input fields
      setNewUserEmail('');
      setNewUserName('');
    } catch (error) {
      alert('Error adding user: ' + error.message);
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Нов корисник</h2>
      <input
        type="text"
        placeholder="Име"
        value={newUserName}
        onChange={(e) => setNewUserName(e.target.value)}
        className={styles.input}
      />
      <input
        type="email"
        placeholder="Емаил"
        value={newUserEmail}
        onChange={(e) => setNewUserEmail(e.target.value)}
        className={styles.input}
      />
      <button onClick={handleAddUser} className={styles.button}>Додај</button>
    </div>
  );
};

export default CreateUserPage;
