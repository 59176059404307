/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import firebase from '../firebaseOLD';
import DebtorsTable from '../elements/DebtorsTable';
import '../css/debtorsPage.css';

const ColorLegend = () => (
  <div className="color-legend">
    <h2>Легенда за преостанати денови</h2>
    <ul>
      <li><span className="color-box" style={{ backgroundColor: '#d8a1c5' }}></span> помалку од 7 дена</li>
      <li><span className="color-box" style={{ backgroundColor: '#f5c6c7' }}></span> помалку од 15 дена</li>
      <li><span className="color-box" style={{ backgroundColor: '#f3f4d1' }}></span> помалку од 30 дена</li>
      <li><span className="color-box" style={{ backgroundColor: '#b2d9b0' }}></span> помалку од 60 дена</li>
      <li><span className="color-box" style={{ backgroundColor: '#b8d9e8' }}></span> помалку од 90 дена</li>
      <li><span className="color-box" style={{ backgroundColor: '#eeeeee' }}></span> помалку од 120 дена</li>
    </ul>
  </div>
);

function DebtorsPage({ userRole, user }) {
  const [mkMKDdata, setMkMKDdata] = useState([]);
  const [mkEURdata, setmkEURdata] = useState([]);
  const [euData, setEuData] = useState([]);

  useEffect(() => {
    const dbRef = firebase.database().ref('/orders');

    const fetchOrdersAndListen = (currency, setData) => {
      dbRef.orderByChild('currency').equalTo(currency).on('value', snapshot => {
        const orders = snapshot.val();
        if (orders) {
          const ordersArray = Object.keys(orders)
            .filter(key => orders[key].Status === "Invoiced")
            .map(key => ({
              OrderID: key,
              ...orders[key]
            }));
          setData(ordersArray);
        } else {
          setData([]);
        }
      });
    };

    fetchOrdersAndListen('MK-MKD', setMkMKDdata);
    fetchOrdersAndListen('MK-EUR', setmkEURdata);
    fetchOrdersAndListen('EU-EUR', setEuData);

    return () => {
      dbRef.off();
    };
  }, []);

  if (userRole !== 'admin' && userRole !== 'user') {
    return <h1 color="red"> Неавторизиран пристап</h1>;
  }

  return (
    <div className="debtors-page">
      <h1>Должници</h1>
      <ColorLegend />
      <div className="table-container">
        <div className="table-item">
          <DebtorsTable user={user} userRole={userRole} vendor="MK-Denari" orders={mkMKDdata} />
        </div>
        <div className="table-item">
          <DebtorsTable user={user} userRole={userRole} vendor="MK-EUR" orders={mkEURdata} />
        </div>
        <div className="table-item">
          <DebtorsTable user={user} userRole={userRole} vendor="EU" orders={euData} />
        </div>
      </div>
    </div>
  );
}

export default DebtorsPage;
