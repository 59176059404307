/* eslint-disable react/prop-types */
import React from 'react';
import '../css/OverdueWidget.css'; // Import the new CSS file
import { NavLink } from 'react-router-dom';

const OverdueWidget = ({ orders }) => {
  // Function to format a number to the string format XXX,XXX.XX
  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  };

  // Function to parse date from DD-MM-YYYY string
  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day); // Months are 0-based in JavaScript
  };

  // Function to parse a string formatted number (XXX,XXX.XX) to a float
  const parseFormattedNumber = (numberStr) => {
    return parseFloat(numberStr.replace(/,/g, ''));
  };

  const getDueDateClass = (dueDate) => {
    const today = new Date();
    const due = parseDate(dueDate);
    const diffTime = due - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 1) return 'purple'; // Less than 1 day overdue
    if (diffDays < 7) return 'pale-orange'; // Less than 7 days overdue
    if (diffDays < 15) return 'pale-yellow'; // Less than 15 days overdue
    if (diffDays < 30) return 'pale-green'; // Less than 30 days overdue
    if (diffDays < 60) return 'pale-blue'; // Less than 60 days overdue
    if (diffDays < 90) return 'gray'; // Less than 90 days overdue
    return '';
  };

  return (
    <div className="macos-window">
      <div className="macos-header">
        <h2 className="window-title">Фактури со изминат рок</h2>
        <NavLink to="/debtors" exact activeClassName="active" className="all-events-link">
          Сите Должници
        </NavLink>
      </div>

      {/* Scrollable content area */}
      <div className="scrollable-content">
        {/* Color Legend */}
        <div className="color-legend">
          <ul>
            <li><span className="color-box" style={{ backgroundColor: '#d8a1c5' }}></span> помалку од 1 ден</li>
            <li><span className="color-box" style={{ backgroundColor: '#f5c6c7' }}></span> помалку од 7 дена</li>
            <li><span className="color-box" style={{ backgroundColor: '#f9f3c6' }}></span> помалку од 15 дена</li>
          </ul>
        </div>

        <table className="orders-table orders-table-3">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Invoice Number</th>
              <th>Buyer</th>
              <th>Amount Due</th>
              <th>Due Date</th>
            </tr>
          </thead>
          {orders.length > 0 ? (
            <tbody>
              {orders.map((order) => {
                const rowClass = getDueDateClass(order.dueDate);
                return (
                  <tr key={order.OrderID} className={rowClass}>
                    <td>{order.OrderID}</td>
                    <td>{order.invoiceNumber}</td>
                    <td>{order.Buyer}</td>
                    <td>{formatNumber(parseFormattedNumber(order.totalSum) - parseFormattedNumber(order.Advance) || 0)}</td>
                    <td>{order.dueDate}</td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="5">Нема фактури со изминат рок</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default OverdueWidget;
