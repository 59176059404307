/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import ActivityWidget from '../elements/EventWidget';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { FaEdit, FaSave } from 'react-icons/fa'; // Import icons
import '../css/Dashboard.css'; // Import CSS for animation
import OverdueWidget from '../elements/OverdueWidget';
import firebase from '../firebaseOLD';

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = ({ user }) => {
  const [layout, setLayout] = useState([
    { i: 'chart', x: 0, y: 0, w: 3, h: 18 },
    { i: 'table', x: 3, y: 0, w: 8, h: 9 },
    { i: 'stat', x: 3, y: 9, w: 8, h: 9}
  ]);
  const [isEditing, setIsEditing] = useState(false);
  const [overdueOrders, setOverdueOrders] = useState([]);

  const handleEditClick = () => {
    setIsEditing(prev => !prev);
  };

  useEffect(() => {
    const dbRef = firebase.database().ref('/orders');

    // Define the date range for filtering
    const today = new Date();
    const oneWeekLater = new Date(today);
    oneWeekLater.setDate(today.getDate() + 7);

    const startOfYear2024 = new Date('2024-01-01');
    const oneWeekLaterISO = oneWeekLater.toISOString().split('T')[0];
    const startOfYear2024ISO = startOfYear2024.toISOString().split('T')[0];

    // Utility function to convert a date string from DD-MM-YYYY to YYYY-MM-DD
    const toISODate = dateStr => {
      const [day, month, year] = dateStr.split('-').map(Number);
      if (year && month && day) {
        const date = new Date(year, month - 1, day);
        return isNaN(date.getTime()) ? null : date.toISOString().split('T')[0];
      }
      return null;
    };

    // Fetch orders and filter them
    const fetchOrdersAndListen = () => {
      dbRef.orderByChild('Status').equalTo("Invoiced").on('value', snapshot => {
        const orders = snapshot.val();
        if (orders) {
          const ordersArray = Object.keys(orders)
            .map(key => {
              const order = orders[key];
              const orderDateISO = toISODate(order.dueDate);
              if (!orderDateISO) {
                console.warn(`Invalid or missing dueDate for order ${key}`);
                return null;
              }

              // Filter orders by date range
              return (
                orderDateISO >= startOfYear2024ISO &&
                orderDateISO <= oneWeekLaterISO
              ) ? { OrderID: key, ...order } : null;
            })
            .filter(order => order !== null); // Remove null values from array

          setOverdueOrders(ordersArray);
        } else {
          setOverdueOrders([]);
        }
      });

      console.log(JSON.stringify(overdueOrders));
    };

    fetchOrdersAndListen();

    return () => {
      dbRef.off(); // Clean up the listener
    };
  }, []);

  const handleSaveClick = () => {
    // Save layout logic here
    setIsEditing(false);
  };

  const layouts = {
    lg: layout,
    md: layout,
    sm: layout,
    xs: layout,
    xxs: layout,
  };

  return (
    <div>
      <h1>Добар ден, {user.displayName}</h1>
      <div className="dashboard-header">
        <button
          className={`edit-button ${isEditing ? 'editing' : ''}`}
          onClick={isEditing ? handleSaveClick : handleEditClick}
        >
          {isEditing ? (
            <FaSave className="icon" />
          ) : (
            <FaEdit className="icon" />
          )}
        </button>
      </div>
      <div className="dashboard">
        <ResponsiveGridLayout
          className="layout"
          layouts={layouts}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={30}
          onLayoutChange={newLayout => setLayout(newLayout)}
          isDraggable={isEditing}
          isResizable={isEditing}
        >
          <div key="chart">
            <ActivityWidget userName={user.displayName} />
          </div>
          <div key="table">
            <OverdueWidget orders={overdueOrders} />
          </div>
        </ResponsiveGridLayout>
      </div>
    </div>
  );
};

export default Dashboard;
