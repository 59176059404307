// src/pages/CompanyPage.js
import React from 'react';
import CompanyList from '../elements/CompanyList';
import '../css/CompanyPage.css'; // Import CSS for styling

const CompanyPage = () => {
  return (
    <div className="company-page">
      <CompanyList />
    </div>
  );
};

export default CompanyPage;
