/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import firebase from '../firebaseOLD';
import '../css/editOrderForm.css';

const GenerateInvoiceModal = ({ onClose, currentOrderID }) => {
  const [updatedOrder, setUpdatedOrder] = useState({
    totalSum: '',
    dueDate: '',
    currency: 'MK-MKD',
    invoiceNumber: '',
    otherDocumentLink: '',
  });
  const [uploading, setUploading] = useState({ other: false });
  const [errors, setErrors] = useState({ dueDate: '' });
  const [orderStatus, setOrderStatus] = useState('');
  const [buyer, setBuyer] = useState('');
  const [currentInvoiceNumber, setCurrentInvoiceNumber] = useState(0);
  const [currentInvoiceNumberEU, setCurrentInvoiceNumberEU] = useState(0);
  const [jurisdiction, setJurisdiction] = useState('');

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const generateInvoiceNumber = (currentNumber, isEU) => {
    const yearLastTwoDigits = new Date().getFullYear().toString().slice(-2);
    return `${String(currentNumber).padStart(3, '0')}\\${yearLastTwoDigits}${isEU ? 'B' : ''}`;
  };

  useEffect(() => {
    const today = formatDate(new Date());
    setUpdatedOrder((prevState) => ({
      ...prevState,
      dueDate: today,
    }));

    const fetchOrderDetails = async () => {
      const orderRef = firebase.database().ref(`orders/${currentOrderID}`);
      const snapshot = await orderRef.once('value');
      const orderData = snapshot.val();
      if (orderData) {
        setOrderStatus(orderData.Status || '');
        setBuyer(orderData.Buyer || '');
        setJurisdiction(orderData.Jurisdiction || '');

        const invoiceCounterRef = firebase.database().ref('invoiceCounter');
        const counterSnapshot = await invoiceCounterRef.once('value');
        const currentNumber = parseInt(counterSnapshot.val()) || 0;

        const invoiceCounterEURef = firebase.database().ref('invoiceCounterEU');
        const counterEUSnapshot = await invoiceCounterEURef.once('value');
        const currentNumberEU = parseInt(counterEUSnapshot.val()) || 0;

        setCurrentInvoiceNumber(currentNumber);
        setCurrentInvoiceNumberEU(currentNumberEU);
        setUpdatedOrder((prevState) => ({
          ...prevState,
          invoiceNumber: generateInvoiceNumber(orderData.Jurisdiction === 'EU' ? currentNumberEU : currentNumber, orderData.Jurisdiction === 'EU'),
        }));
      }
    };

    fetchOrderDetails();
  }, [currentOrderID]);

  useEffect(() => {
    setUpdatedOrder((prevState) => ({
      ...prevState,
      invoiceNumber: generateInvoiceNumber(jurisdiction === 'EU' ? currentInvoiceNumberEU : currentInvoiceNumber, jurisdiction === 'EU'),
    }));
  }, [currentInvoiceNumber, currentInvoiceNumberEU, jurisdiction]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedOrder((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'totalSum') {
      const formattedValue = parseFloat(value.replace(/,/g, '')).toLocaleString(undefined, { minimumFractionDigits: 2 });
      setUpdatedOrder((prevState) => ({
        ...prevState,
        [name]: formattedValue,
      }));
    }
  };

  const handleDateValidation = () => {
    const [day, month, year] = updatedOrder.dueDate.split('-');
    const isValidDate = day && month && year &&
      day.length === 2 && month.length === 2 && year.length === 4 &&
      !isNaN(new Date(`${year}-${month}-${day}`).getTime());

    if (!isValidDate) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        dueDate: 'Invalid date format. Please enter a date in DD-MM-YYYY format.',
      }));
      return false;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      dueDate: '',
    }));
    return true;
  };

  const handleDocumentUpload = (e, type) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading((prev) => ({ ...prev, [type]: true }));

    const storageRef = firebase.storage().ref();
    const docRef = storageRef.child(`fakturi/${currentOrderID}/${updatedOrder.invoiceNumber}`);

    docRef
      .put(file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          setUpdatedOrder((prevState) => ({
            ...prevState,
            [`otherDocumentLink`]: url,
          }));
          setUploading((prev) => ({ ...prev, [type]: false }));
        });
      })
      .catch(() => setUploading((prev) => ({ ...prev, [type]: false })));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (uploading.other) {
      alert('Please wait until all files are uploaded.');
      return;
    }

    if (!handleDateValidation()) {
      alert('Please fix the errors before submitting.');
      return;
    }

    const orderRef = firebase.database().ref(`orders/${currentOrderID}`);
    await orderRef.update({
      Status: 'Invoiced',
      OrderComplete: false,
      invoiceNumber: updatedOrder.invoiceNumber,
      currency: updatedOrder.currency,
      dueDate: updatedOrder.dueDate,
      totalSum: updatedOrder.totalSum,
      InvoiceLink: updatedOrder.otherDocumentLink,
    });

    if (jurisdiction === 'EU') {
      const invoiceCounterEURef = firebase.database().ref('invoiceCounterEU');
      await invoiceCounterEURef.set(currentInvoiceNumberEU + 1);
    } else {
      const invoiceCounterRef = firebase.database().ref('invoiceCounter');
      await invoiceCounterRef.set(currentInvoiceNumber + 1);
    }

    console.log('Invoice created and order updated successfully!');
    onClose();
  };

  const incrementInvoiceNumber = () => {
    if (jurisdiction === 'EU') {
      setCurrentInvoiceNumberEU((prev) => prev + 1);
    } else {
      setCurrentInvoiceNumber((prev) => prev + 1);
    }
  };

  const decrementInvoiceNumber = () => {
    if (jurisdiction === 'EU') {
      setCurrentInvoiceNumberEU((prev) => Math.max(prev - 1, 0));
    } else {
      setCurrentInvoiceNumber((prev) => Math.max(prev - 1, 0));
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <form onSubmit={handleSubmit} className="form-container">
          <div className="form-group">
            <label className="label">Број на нарачка:</label>
            <input
              type="text"
              name="orderID"
              value={currentOrderID}
              disabled
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Купец:</label>
            <input
              type="text"
              name="buyer"
              value={buyer}
              disabled
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Статус на нарачка:</label>
            <input
              type="text"
              name="orderStatus"
              value={orderStatus}
              disabled
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Бр. на Фактура:</label>
            <div className="invoice-number-container">
              <button type="button" onClick={decrementInvoiceNumber}>-</button>
              <input
                type="text"
                name="invoiceNumber"
                value={updatedOrder.invoiceNumber}
                disabled
                className="input"
              />
              <button type="button" onClick={incrementInvoiceNumber}>+</button>
            </div>
          </div>
          <div className="form-group">
            <label className="label">Вкупна сума:</label>
            <input
              type="text"
              name="totalSum"
              value={updatedOrder.totalSum}
              onChange={handleChange}
              onBlur={handleBlur}
              className="input"
              required
            />
          </div>
          <div className="form-group">
            <label className="label">Валута:</label>
            <select
              name="currency"
              value={updatedOrder.currency}
              onChange={handleChange}
              className="input"
              required
            >
              <option value="MK-MKD">MK-MKD</option>
              <option value="MK-EUR">MK-EUR</option>
              <option value="EU-EUR">EU-EUR</option>
            </select>
          </div>
          <div className="form-group">
            <label className="label">Датум за наплата (DD-MM-YYYY):</label>
            <input
              type="text"
              name="dueDate"
              value={updatedOrder.dueDate}
              onChange={handleChange}
              placeholder="DD-MM-YYYY"
              className="input"
              required
            />
            {errors.dueDate && <div className="error-message">{errors.dueDate}</div>}
          </div>
          <div className="form-group">
            <label className="label">Фактура:</label>
            {updatedOrder.InvoiceLink ? (
              <>
                <a href={updatedOrder.OtherLink} target="_blank" rel="noopener noreferrer">
                  Прегледај фактура
                </a>
                <input type="file" onChange={(e) => handleDocumentUpload(e, 'Other')} />
              </>
            ) : (
              <input type="file" required onChange={(e) => handleDocumentUpload(e, 'Other')} />
            )}
          </div>
          <button type="submit" className="btn-save">
            Испрати во должници
          </button>
          <button type="button" onClick={onClose} className="btn-discard">
            Отфрли промени
          </button>
        </form>
      </div>
    </div>
  );
};

export default GenerateInvoiceModal;
