// Updated InvoiceIcon.js

import React from 'react';

const InvoiceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
      <path d="M4 0h8a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1zm0 1v13h8V1H4z"/>
      <path fill="#currentColor" d="M5 4h6v1H5zM5 6h6v1H5zM5 8h6v1H5zM5 10h6v1H5zM5 12h4v1H5z"/>
    </svg>
  );
};

export default InvoiceIcon;

