// src/App.js
import './App.css';
import TableHome from './pages/TableHome';
import SignIn from './pages/LoginPage';
import ProfileSettings from './pages/ProfileSettings';
import ForgotPassword from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import NavBar from './elements/NavBar';
import AdminPanel from './pages/AdminPanel';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { ref, get } from 'firebase/database';
import { auth, db } from './firebase';
import PrivateRoute from './elements/PrivateRoute';
import Archive from './pages/Archive';
import DebtorsPage from './pages/DebtorsPage';
import CompanyPage from './pages/CompaniesPage';
import EventPage from './pages/EventPage';
import Dashboard from './pages/Dashboard';

function App() {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (user) {
        try {
          const userRef = ref(db, `users/${user.uid}/role`);
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            setUserRole(snapshot.val());
          } else {
            setUserRole('undefined');
          }
        } catch (error) {
          console.error('Error fetching user role:', error);
          setUserRole('undefined');
        }
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setLoading(false);
      } else {
        setUser(null);
        setLoading(false);
      }
    });

    fetchUserRole();
    return () => unsubscribe();
  }, [user]);

  const handleLogout = () => {
    auth.signOut();
  };

  return (
    <Router>
      <div className="nav">
        {user && <NavBar user={user} onLogout={handleLogout} />}
      </div>
      <div className='App'>
        <Routes>
          <Route
            exact
            path="/"
            element={
              loading ? (
                <div>Loading...</div>
              ) : user ? (
                <TableHome userName={user.displayName} userRole={userRole} user={user} />
              ) : (
                <Navigate to="/signin" replace />
              )
            }
          />
          <Route
            path="/signin"
            element={
              user ? (
                <Navigate to="/" replace />
              ) : (
                <SignIn />
              )
            }
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route
            path="/adminPanel"
            element={
              <PrivateRoute
                element={<AdminPanel />}
                user={user}
                userRole={userRole}
                requiredRole="admin"
              />
            }
          />
          <Route
            path="/debtors"
            element={
              <PrivateRoute
                element={<DebtorsPage userRole={userRole}/>}
                user={user}
              />
            }
          />
          <Route
            path="/archive"
            element={
              <PrivateRoute
                element={<Archive userRole={userRole}/>}
                user={user}
              />
            }
          />
                    <Route
            path="/activities"
            element={
              <PrivateRoute
                element={<EventPage user={user} userRole={userRole}/>}
                user={user}
              />
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute
                element={<ProfileSettings user={user} />}
                user={user}
              />
            }
          />
          <Route
            path="/companies"
            element={
              <PrivateRoute
              element={<CompanyPage/>}
              user={user}
            />} 
          />
        <Route
            path="/dashboard"
            element={
              <PrivateRoute
              element={<Dashboard userRole={userRole} user={user}/>}
              user={user}
            />} 
          />
        </Routes>
      </div>
      <footer className="copyrightBanner">
        <p>&copy; {new Date().getFullYear()} Teodor Nedevski licensed to Somitek Makedonija dooel. All rights reserved.</p>
      </footer>
    </Router>
  );
}

export default App;
