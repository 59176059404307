/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import firebase from '../firebaseOLD';
import '../css/InvoiceMultiple.css';
import InvoicingMultipleCard from './InvoicingMultipleCard';

const InvoiceMultipleModal = ({ onClose, ordersOANum }) => {
    const [orderKeys, setOrderKeys] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState({});

    useEffect(() => {
        const fetchOrderDetails = async () => {
            const orderRef = firebase.database().ref('orders');
            const snapshot = await orderRef.orderByChild('OriginationNumber').equalTo(ordersOANum).once('value');
            const ordersData = snapshot.val();

            if (ordersData) {
                const numbers = Object.keys(ordersData);
                setOrderKeys(numbers);
            } else {
                setOrderKeys([]);
            }
        };

        fetchOrderDetails();
        console.log(JSON.stringify(submissionStatus));
    }, [ordersOANum]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        let allSubmitted = true;
        const status = {};

        // Notify each child component to submit
        const promises = orderKeys.map(firebaseId => {
            return new Promise((resolve) => {
                const handleStatus = (id, success) => {
                    status[id] = success;
                    resolve();
                };

                // Trigger child component submission
                // Assuming children are passing their status through `onSubmitStatus`
                handleStatus(firebaseId, true);
            });
        });

        await Promise.all(promises);

        if (Object.values(status).includes(false)) {
            allSubmitted = false;
        }

        setSubmissionStatus(status);

        if (allSubmitted) {
            console.log('All invoices created and orders updated successfully!');
            setIsSubmitting(false);
            console.log(JSON.stringify(submissionStatus))
            onClose(); // Close the modal after updating
        } else {
            console.error('Some invoices failed to be created.');
            setIsSubmitting(false);
        }

    };

    return (
        <div className="modal">
            <div className="modal-content-multiple">
                <span className="close" onClick={onClose}>
                    &times;
                </span>
                <h2>Нарачки со ОА број: {ordersOANum}</h2>
                <form onSubmit={handleSubmit}>
                    <table className="orders-table orders-table-2">
                        <thead>
                            <tr>
                                <th>Број на нарачка</th>
                                <th>Документ</th>
                                <th>Нарачател</th>
                                <th>бр. Фактура</th>
                                <th>Сума</th>
                                <th>Валута</th>
                                <th>Рок за наплата</th>
                                <th>Прикачи фактура</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderKeys.length > 0 ? (
                                orderKeys.map(firebaseId => (
                                    <InvoicingMultipleCard
                                        key={firebaseId}
                                        firebaseId={firebaseId}
                                        isSubmitting={isSubmitting}
                                        onSubmitStatus={(id, success) => setSubmissionStatus(prev => ({ ...prev, [id]: success }))}
                                    />
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8">Нема Активни</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <button type="submit" className="submit-button" disabled={isSubmitting}>
                        Ажурирај
                    </button>
                </form>
            </div>
        </div>
    );
};

export default InvoiceMultipleModal;
