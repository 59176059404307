/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import '../css/ordersTable.css';
import firebase from '../firebaseOLD.js'; // Import Firebase
import InvoiceIcon from '../Icons/InvoiceIcon.js';
import EditOrderForm from './EditOrderForm.js';
import * as XLSX from 'xlsx';
import { ref, get } from 'firebase/database';
import { db2 } from '../firebase.js';
import CompanyInfoModal from './CompanyInfoModal'; // Import the CompanyInfoModal component


const OrdersTableArchive = ({ user, userRole, orders, vendor }) => {
  const [previewUrl, setPreviewUrl] = useState('');
  const [editingOrder, setEditingOrder] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [showCompanyInfoModal, setShowCompanyInfoModal] = useState(false);



  const handleEditOrder = (order) => {
    setEditingOrder(order);
  };

  const handleCloseModal = () => {
    setEditingOrder(null);
  };

  const deleteOrder = (orderId, invoiceLink) => {
    const ordersRef = firebase.database().ref('orders').child(orderId);

    ordersRef.remove()
      .then(() => {
        console.log(`Order with ID ${orderId} deleted successfully.`);
      })
      .catch((error) => {
        console.error(`Error removing order with ID ${orderId}: `, error);
      });

    if (invoiceLink) {
      const storageRef = firebase.storage().refFromURL(invoiceLink);

      storageRef.delete()
        .then(() => {
          console.log(`Invoice file linked to order ${orderId} deleted successfully.`);
        })
        .catch((error) => {
          console.error(`Error deleting invoice file linked to order ${orderId}: `, error);
        });
    }
  };

  const handlePreviewInvoice = (invoiceLink) => {
    setPreviewUrl(invoiceLink);
  };

  const handleClosePreview = () => {
    setPreviewUrl('');
  };

  const handleOpenCompanyInfo = async (buyerName) => {
    try {
      const companiesRef = ref(db2, 'companies');
      const snapshot = await get(companiesRef);
      if (snapshot.exists()) {
        const companies = snapshot.val();
        const companyList = Object.values(companies);
        const foundCompany = companyList.find(company => company.ime === buyerName);
        if (foundCompany) {
          setCompanyInfo(foundCompany);
          setShowCompanyInfoModal(true);
        } else {
          console.log('Company not found');
        }
      } else {
        console.log('No data available');
      }
    } catch (error) {
      console.error('Error fetching company info:', error);
    }
  };

  // Function to handle exporting to Excel
  const handleExportToExcel = () => {
    // Prepare data for the Excel file
    const table = document.getElementById('orders-table-container');
    const rows = table.querySelectorAll('tr');
    
    const data = Array.from(rows).map(row => {
      const cols = row.querySelectorAll('td, th');
      return Array.from(cols).map(col => col.innerText);
    });
    
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');
    
    // Export to Excel
    XLSX.writeFile(wb, 'orders_table.xlsx');
  };

  return (
    <div className="orders-table-container" id="orders-table-container">
      <button onClick={handleExportToExcel} className="export-button">Export to Excel</button>
      <h2>{vendor}</h2>
      {previewUrl && (
        <div className="invoice-preview">
          <div className="preview-content">
            <button className="close-button" onClick={handleClosePreview}>&times;</button>
            <iframe src={previewUrl} title="Invoice Preview" className="preview-frame"></iframe>
          </div>
        </div>
      )}
      <table className="orders-table orders-table-2">
        <thead>
          <tr>
            <th>Број на нарачка</th>
            <th>Број на фактура</th>
            <th>Датум - нарачка</th>
            <th>ОА Број</th>
            <th>Производител</th>
            <th>д. Испорака</th>
            <th>д. Уплата</th>
            <th>Купец</th>
            <th>Коментари</th>
            <th>Док.</th>
            <th>Фактура</th>
            <th>Фирма</th>
            <th>Одговорно Лице</th>
            <th>Аванс Сума</th>
            <th>Статус</th>
            {userRole == "admin" &&(
            <th>Избриши</th>
            )}
          </tr>
        </thead>
        {orders.length > 0 ? (
          <tbody>
            {Object.keys(orders).map((firebaseId) => {
              const order = orders[firebaseId];
              return (
                <tr key={firebaseId}>
                    {userRole == "admin" ?(
                                          <td>

                      <a className='order-link' href="#" onClick={() => handleEditOrder(order)}>
                        {order.OrderID}
                      </a>
                                          </td>

                    ) : (
                      <td>

                      {order.OrderID}
                      </td>

                    )}
                  <td>{order.invoiceNumber}</td>
                  <td>{order.OrderDate}</td>
                  <td>{order.OriginationNumber}</td>
                  <td>{order.OrderVendor}</td>
                  <td>{order.DateOfDelivery}</td>
                  <td>{order.DateOfPayment}</td>
                  <td>
                    <a className='order-link' href="#" onClick={() => handleOpenCompanyInfo(order.Buyer)}>
                      {order.Buyer}
                    </a>
                  </td>                  
                  <td>{order.Comment}</td>
                  <td>
                    {order.OtherLink !== '' && (
                      <button onClick={() => handlePreviewInvoice(order.OtherLink)}>
                        <InvoiceIcon />
                      </button>
                    )}
                  </td>
                  <td>
                    {order.InvoiceLink !== '' && (
                      <button onClick={() => handlePreviewInvoice(order.InvoiceLink)}>
                        <InvoiceIcon />
                      </button>
                    )}
                  </td>
                  <td>{order.Jurisdiction}</td>
                  <td>{order.Username}</td>
                  <td>{order.Advance}</td>
                  <td>{order.Status}</td>
                  {userRole == "admin" &&(
                     <td>
                     <button onClick={() => deleteOrder(order.OrderID)}>Delete</button>
                   </td>
                  )}
              </tr>
            );
          })}
        </tbody>
      ) : (
        <p>Нема Активни</p>
      )}
      </table>
      {editingOrder && (
        <EditOrderForm
          order={editingOrder}
          user={user}
          onClose={handleCloseModal}
        />
      )}
      {showCompanyInfoModal && companyInfo && (
        <CompanyInfoModal
          company={companyInfo}
          onClose={() => setShowCompanyInfoModal(false)}
        />
      )}
    </div>
  );
};

export default OrdersTableArchive;
